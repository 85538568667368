import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import partnerProfileService from "../service/partner/PartnerProfileService";
import userService from "../service/UserService";

const Employees = (props) => {
  const gridRef = useRef(null);
  const [orgId, setOrgId] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [organization, setOrganization] = useState({});
  const [employeesData, setEmployeesData] = useState([]);

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };

  useEffect(() => {
    if (props.data && props.data.orgId && props.data.orgType) {
      setOrgId(props.data.orgId);
      setOrgType(props.data.orgType);
      setOrganization(props.data.org);
    }
  }, [props.data]);

  useEffect(() => {
    if (orgId && orgType) {
      switch (orgType) {
        case "partner":
          getPartnerEmployees();
          break;
        case "center":
          break;
        default:
          setEmployeesData([]);
      }
    }
  }, [orgId, orgType]);

  const getPartnerEmployees = async () => {
    try {
      let emps = await partnerProfileService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const emailExistsAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // get user, add organization subcollection to user, add to partner employees
        const user = await userService.getUserByEmail(employeeData.email);
        const userOrg = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        const newOrg = {
          ...organization,
          org_type: "partner",
          org_id: orgId,
          role: "partner",
          is_representative: employeeData.is_representative,
          is_admin: employeeData.is_admin,
        };
        if (userOrg) {
          let mergedOrg = { ...userOrg, ...newOrg };
          mergedOrg.is_deleted = false;
          await userService.updateOrganization(
            employeeData.id,
            orgId,
            mergedOrg
          );
        } else {
          await userService.createOrganizationWithOrgId(user.id, orgId, newOrg);
        }
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = {
            ...employeeData,
            status: "active",
            user_id: user.id,
          };
          await partnerProfileService.createEmployeeWithUserId(
            user.id,
            orgId,
            newEmp
          );
          // TODO send email
        }
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const emailNotExistingAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Changed to save to employees subcollection for now then TODO: create a link account if user registers.
        let emp = await partnerProfileService.getEmployeeByEmail(
          orgId,
          employeeData.email
        );
        if (emp) {
          let tmp = Object.assign(employeeData);
          delete tmp["id"];
          let newEmp = { ...emp, ...tmp };
          newEmp.is_deleted = false;
          await partnerProfileService.updateEmployee(emp.id, orgId, newEmp);
        } else {
          const newEmp = { ...employeeData, status: "invited" };
          await partnerProfileService.createEmployee(orgId, newEmp);
          // TODO send email
        }
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const editAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // edit employee
        await partnerProfileService.updateEmployee(
          employeeData.id,
          orgId,
          employeeData
        );
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const deleteAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // delete employee
        await partnerProfileService.updateEmployee(employeeData.id, orgId, {
          is_deleted: true,
        });
        const userOrg = await userService.getOrganizationById(
          employeeData.id,
          orgId
        );
        if (userOrg) {
          await userService.updateOrganization(employeeData.id, orgId, {
            is_deleted: true,
          });
        }
        break;
      case "center":
        break;
      default:
      // do nothing
    }
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const exists = await userService.checkIfEmailExists(args.data.email);
        if (exists) {
          await emailExistsAction(args.data);
        } else {
          await emailNotExistingAction(args.data);
        }
        await getPartnerEmployees();
      } else if (args.action === "edit") {
        await editAction(args.data);
      }
    } else if (args.requestType === "delete") {
      await deleteAction(args.data[0]);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Member" : "Add New Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 100);
        }
      }
    }
  };

  const handleAddEmployee = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  return (
    <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
      <div className="mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left  text-xs leading-[normal] tracking-[normal] text-dodgerblue">
        <div className="mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left  text-xs leading-[normal] tracking-[normal] text-dodgerblue">
          <div className="flex w-[159px] flex-col items-start justify-start">
            <a className="relative inline-block min-w-[52px] font-bold leading-[21px] text-[inherit] [text-decoration:none]">
              Staff
            </a>
            <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
              Last Edited:
            </div>
          </div>
          <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
            <div className="flex flex-row items-start justify-start gap-2.5">
              <button
                type="button"
                className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                onClick={handleAddEmployee}
              >
                <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                      fill="white"
                    />
                    <path
                      d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                      fill="white"
                    />
                    <path
                      opacity="0.4"
                      d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <a className="relative inline-block text-left  text-xs font-semibold text-white [text-decoration:none]">
                  Add
                </a>
              </button>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15.5L12 8.5L19 15.5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <GridComponent
          dataSource={employeesData}
          editSettings={{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true,
            mode: "Dialog",
          }}
          ref={gridRef}
          actionBegin={actionBegin}
          allowFiltering={true}
          filterSettings={filterSettings}
          allowSorting={true}
          sortSettings={sortSettings}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              width={0}
              isPrimaryKey={true}
              isIdentity={true}
              visible={false}
            />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              validationRules={{ required: true }}
            />
            <ColumnDirective field="mobile_number" headerText="Mobile Number" />
            <ColumnDirective field="title" headerText="Title" dro />
            <ColumnDirective
              field="is_representative"
              headerText="Representative"
              editType="booleanedit"
            />
            <ColumnDirective
              field="is_admin"
              headerText="Admin"
              editType="booleanedit"
            />
            <ColumnDirective
              headerText="Actions"
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: '<i class="fas fa-edit"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default Employees;
