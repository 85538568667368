import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";

const auth = getAuth();

const CenterService = {
  createUserAccount: async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      console.error("Error creating user: ", error);
      throw error;
    }
  },

  addCenter: async (collectionName, data) => {
    try {
      const docRef = await setDoc(
        doc(db, collectionName, data.center_id),
        data
      );
      return docRef;
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  updateCenter: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  deleteUserAccount: async (uid) => {
    try {
      const user = auth.currentUser;

      // Ensure the user is authenticated and the current user matches the user to delete
      if (user && user.uid === uid) {
        await deleteUser(user);
      } else {
        // If not the current user, you might need to re-authenticate or use an admin SDK
        console.error("User mismatch or not authenticated.");
      }
    } catch (error) {
      console.error("Error deleting user from authentication: ", error);
      throw error;
    }
  },

  deleteCenter: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting document: ", error);
      throw error;
    }
  },

  getCenterById: async (id) => {
    try {
      const centerDocRef = doc(db, "centers", id);
      const centerDoc = await getDoc(centerDocRef);
      if (centerDoc.exists()) {
        return { id: centerDoc.id, ...centerDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  },

  getAllCenters: async () => {
    try {
      // Query centers from the "users" collection
      const usersQuery = query(
        collection(db, "users"),
        where("user_role", "==", "center")
      );
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Query centers from the "center-for-approval" collection
      const centersQuery = query(
        collection(db, "center-for-approval"),
        where("user_role", "==", "center")
      );
      const centersSnapshot = await getDocs(centersQuery);
      const centers = centersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Combine both users and centers data, filter out duplicates based on 'email'
      const combinedData = [...users, ...centers].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.email === value.email)
      );

      return combinedData;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },
};

export default CenterService;
