import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import PropTypes from "prop-types";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import {
  householdMembers,
  pets,
  USStates,
  surveyQuestions,
} from "../../pages/datasource";
import { Internationalization } from "@syncfusion/ej2-base";
import SurveyQuestion from "./SurveyQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import citizenProfileService from "../../service/citizen/CitizenProfile";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import "../../pages/style.css";
import { Query } from "@syncfusion/ej2-data";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import DatePickerTemplate from "../../components/DatePickerTemplate";

const scrollToTop = (event) => {
  event.preventDefault();
  const header = document.getElementById("survey-questions-header");
  if (header) {
    header.scrollIntoView({ behavior: "smooth" });
  }
};
const scrollToBottom = (event) => {
  event.preventDefault();
  const footer = document.getElementById("survey-questions-footer");
  if (footer) {
    footer.scrollIntoView({ behavior: "smooth" });
  }
};

const chunkQuestions = (questions, size) => {
  const chunkedArr = [];
  for (let i = 0; i < questions.length; i += size) {
    chunkedArr.push(questions.slice(i, i + size));
  }
  return chunkedArr;
};

const questionGroups = chunkQuestions(surveyQuestions, 3);

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",

  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const intl = new Internationalization();

const formatDate = (date) => {
  if (date) {
    return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
  }
  return "";
};
const formattedData = householdMembers.map((item) => ({
  ...item,
  date_of_birth: formatDate(item.date_of_birth),
  household_member_id: item.household_member_id,
}));
const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

const militaryvetTypes = [{ value: "No" }, { value: "Yes" }];

const militaryvetParams = {
  params: {
    allowFiltering: true,
    dataSource: militaryvetTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const ImmediateResponseSurvey = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDisplacedInfoVisible, setIsDisplacedInfoVisible] = useState(true);
  const [displacedEditMode, setDisplacedEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [displacedTempData, setDisplacedTempData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [responses, setResponses] = useState({}); // State to hold responses
  const [dob, setDob] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        await fetchUserSurveyResponses(currentUser.uid); // Fetch survey responses
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchUserSurveyResponses = async (userId) => {
    const docRef = doc(db, "survey_responses", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      setResponses(data.responses);
    } else {
    }
  };

  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    if (citizenData) {
      setData(citizenData);
      console.log(citizenData);
      setTempFormData(citizenData);
      onSetDefaultDisplacedDetails(citizenData);
      if (citizenData.updatedData_at)
        setLastEditedTime(citizenData.updated_at.toDate());

      const householdData = citizenData.household || {};
      setHouseholdFormData(householdData);
      setTempHouseholdFormData(householdData);
      setLastHouseholdEditedTime(
        householdData.updated_at ? householdData.updated_at.toDate() : null
      );

      // Fetch household members
      const householdMembersData =
        await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(householdMembersData);
    }

    // Fetch pets
    const petsData = await citizenProfileService.getPets(userId);
    setPets(petsData);
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const toggleDisplacedInfo = (event) => {
    event.preventDefault();
    setIsDisplacedInfoVisible((prev) => !prev);
  };

  const onEditDisplacedInfo = (toggle) => (event) => {
    event.preventDefault();
    setDisplacedEditMode(() => toggle);
  };

  const onChangeDisplacedDetails = ({ target: { name, value } }) => {
    setDisplacedTempData((prev) => ({ ...prev, [name]: value }));
  };

  const onSaveDisplacedDetails = useCallback(async () => {
    await citizenProfileService.updateDisplacedDetails(
      userId,
      displacedTempData
    );
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    setData(citizenData);
    onSetDefaultDisplacedDetails(citizenData);
  }, [displacedTempData, userId]);

  const onDisplaced = async ({ checked }) => {
    await citizenProfileService.toggleDisplaced(userId, checked);
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    setData(citizenData);
    onSetDefaultDisplacedDetails(citizenData);
  };

  const onSetDefaultDisplacedDetails = (data) => {
    const { d_address_1, d_address_2, d_city, d_state, d_zip } = data || {};
    setDisplacedTempData((prev) => ({
      ...prev,
      d_address_1: d_address_1 || "",
      d_address_2: d_address_2 || "",
      d_city: d_city || "",
      d_state: d_state || "",
      d_zip: d_zip || "",
    }));
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputBlur = async () => {
    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleHouseholdInputBlur = async () => {
    const currentTime = new Date();
    const updatedHouseholdData = {
      ...tempHouseholdFormData,
      updated_at: currentTime,
    };

    try {
      // Fetch the current profile data
      const currentProfile = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      const updatedProfile = {
        ...currentProfile,
        household: updatedHouseholdData,
      };

      // Update the backend with the new household data
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedProfile
      );

      // Update the frontend state
      setHouseholdFormData(updatedHouseholdData);
      setLastHouseholdEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}
        >
          {isEditMode ? (
            name === "birth_date" ? (
              <DatePickerTemplate
                value={tempFormData[name] || ""}
                onChange={(val) =>
                  handleInputChange({ target: { name, value: val } })
                }
              />
            ) : isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {USStates.map((state) => (
                  <option key={state.stateID} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                type="text"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                tempFormData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {tempFormData[name] || placeholder}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDisplacedField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`self-stretch rounded-md ${
            displacedEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}
        >
          {displacedEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                name={name}
                value={displacedTempData[name] || ""}
                onChange={onChangeDisplacedDetails}
                onBlur={onSaveDisplacedDetails}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {USStates.map((state) => (
                  <option key={state.stateID} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                type="text"
                name={name}
                value={displacedTempData[name] || ""}
                onChange={onChangeDisplacedDetails}
                onBlur={onSaveDisplacedDetails}
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                displacedTempData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {displacedTempData[name] || placeholder}
            </div>
          )}
        </div>
      </div>
    );
  };

  {
    /* Household Information Fields*/
  }
  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  const [householdFormData, setHouseholdFormData] = useState({
    annual_income: "",
    ssn: "",
    owner_or_renter: "",
    insurance: false,
    household_size: "",
    disability: false,
    military_vet: false,
  });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({
    ...householdFormData,
  });
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  const toggleHouseholdInfo = (event) => {
    event.preventDefault();
    setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  };

  const startHouseholdEditing = (event) => {
    event.preventDefault();
    setIsHouseholdEditMode(true);
  };

  const saveHouseholdChanges = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const updatedHouseholdData = {
      ...tempHouseholdFormData,
      updated_at: currentTime,
      date_of_birth: tempHouseholdFormData.date_of_birth
        ? new Date(tempHouseholdFormData.date_of_birth).toLocaleDateString(
            "en-US"
          )
        : null,
    };

    try {
      // Fetch the current profile data
      const currentProfile = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      const updatedProfile = {
        ...currentProfile,
        household: updatedHouseholdData,
      };

      // Update the backend with the new household data
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedProfile
      );

      // Update the frontend state
      setHouseholdFormData(updatedHouseholdData);
      setIsHouseholdEditMode(false);
      setLastHouseholdEditedTime(currentTime);
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const cancelHouseholdEditing = (event) => {
    event.preventDefault();
    setTempHouseholdFormData({ ...householdFormData });
    setIsHouseholdEditMode(false);
  };

  const handleHouseholdInputChange = (event) => {
    const { name, value } = event.target;
    setTempHouseholdFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "disability" || name === "military_vet" || name === "insurance"
          ? value === "Yes"
          : value,
    }));
  };

  const renderHouseholdField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isHouseholdEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}
        >
          {isHouseholdEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={handleHouseholdInputChange}
                onBlur={handleHouseholdInputBlur}
              >
                <option value="" disabled className="text-gray-400">
                  {name === "insurance"
                    ? tempHouseholdFormData[name]
                      ? "Yes"
                      : "No"
                    : placeholder}
                </option>
                {name === "owner_or_renter" ? (
                  <>
                    <option value="Owner">Owner</option>
                    <option value="Renter">Renter</option>
                  </>
                ) : (
                  <>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </>
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={
                  name === "insurance"
                    ? tempHouseholdFormData[name]
                      ? "Yes"
                      : "No"
                    : placeholder
                }
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={handleHouseholdInputChange}
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                householdFormData[name] ? "font-bold text-black" : "text-black"
              } text-left`}
            >
              {name === "insurance"
                ? householdFormData[name]
                  ? "Yes"
                  : "No"
                : householdFormData[name] || placeholder}
            </div>
          )}
        </div>
      </div>
    );
  };

  {
    /* Household Members */
  }
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
    useState(null);

  const toggleHouseholdMembers = (event) => {
    event.preventDefault();
    setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  };

  const gridRef = useRef(null);

  const handleAddClick = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure household_member_id and status are set
        const newMemberData = {
          ...args.data,
          household_member_id:
            args.data.household_member_id || generateUniqueID(),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
        };
        await citizenProfileService.addHouseholdMember(userId, newMemberData);
        setDob(() => null);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedMemberData = {
          ...args.data,
          dateOfBirth: formatDate(args.data.dateOfBirth),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
        };
        await citizenProfileService.updateHouseholdMember(
          userId,
          updatedMemberData.id,
          updatedMemberData
        );
        setDob(() => null);
      }
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(
        userId
      );
      setHouseholdMembers(
        updatedMembers.map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth || "mm/dd/yyyy",
        }))
      );
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedMemberData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updateHouseholdMember(
        userId,
        updatedMemberData.id,
        updatedMemberData
      );
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(
        userId
      );
      setHouseholdMembers(
        updatedMembers.map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth || "mm/dd/yyyy",
        }))
      );
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Household Member"
            : "Add New Household Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
      if (args.requestType === "beginEdit") {
        const datepicker = args.form.elements["dateOfBirth"];
        if (datepicker) {
          datepicker.ej2_instances[0].value = new Date(
            args.rowData.dateOfBirth
          );
        }
      }
    }
  };

  // Filter household members to only include those with status true
  const filteredHouseholdMembers = Array.isArray(householdMembers)
    ? householdMembers
        .filter((member) => !member.is_deleted)
        .map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth
            ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "mm/dd/yyyy",
        }))
    : [];
  {
    /*Pets*/
  }
  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  const togglePets = () => {
    setIsPetsVisible(!isPetsVisible);
  };

  const handleAddPet = () => {
    if (petsGridRef.current) {
      petsGridRef.current.addRecord();
    }
  };

  const petsActionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure pet_id and status are set
        const newPetData = {
          ...args.data,
          pet_id: generateUniqueID(),
          is_deleted: false,
        };
        await citizenProfileService.addPet(userId, newPetData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedPetData = {
          ...args.data,
          is_deleted: false,
        };
        // Use the id field for updating, as it corresponds to the Firestore document ID
        await citizenProfileService.updatePet(
          userId,
          updatedPetData.id,
          updatedPetData
        );
      }
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedPetData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updatePet(
        userId,
        updatedPetData.id, // Use id instead of pet_id
        updatedPetData
      );
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Pet" : "Add New Pet";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  // Filter pets to only include those with status true
  const filteredPets = Array.isArray(pets)
    ? pets.filter((pet) => !pet.is_deleted)
    : [];
  {
    /*Survey*/
  }
  const [checkedItems, setCheckedItems] = useState({});

  const handleResponseChange = (question, response) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question]: response,
    }));
  };

  const handleSubmitSurvey = async (event) => {
    event.preventDefault();
    // Check if all questions are answered
    const unansweredQuestions = surveyQuestions.filter(
      (question) => !responses[question.question]
    );
    if (unansweredQuestions.length > 0) {
      alert("Please answer all survey questions before submitting.");
      return; // Stop the submission process
    }

    try {
      const docRef = doc(db, "survey_responses", userId); // Use userId as document ID
      await setDoc(
        docRef,
        {
          user_id: userId,
          survey_id: uuidv4(),
          submitted_at: serverTimestamp(),
          responses: responses,
        },
        { merge: true }
      ); // Use merge to update existing document

      // Prompt for successful submission
      alert("Survey submitted successfully! Thank you for your responses.");
    } catch (error) {
      console.error("Error submitting survey: ", error);
    }
  };

  return (
    <form
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="relative mb-4 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p>
      </div>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              IMPACTED ADDRESS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <div className="flex flex-row gap-4">
              {renderField("First Name", "first_name", "", false, "flex-1")}
              {renderField("Last Name", "last_name", "", false, "flex-1")}
              {renderField(
                "Date of Birth",
                "birth_date",
                "MM/DD/YYYY",
                false,
                "flex-1"
              )}
            </div>

            {renderField("Address 1", "address1", "")}
            {renderField("Address 2", "address2", "")}
            <div className="flex flex-row gap-4">
              {renderField("City", "city", "", false, "flex-1")}
              {renderField(
                "Select a state",
                "state",
                "Select a state",
                true,
                "flex-1"
              )}
              {renderField("Zip", "zip", "e.g., 62704", false, "flex-1")}
            </div>
            <div className="flex flex-row gap-4">
              {renderField("Email", "email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "mobile_number",
                "",
                false,
                "flex-1"
              )}
              <div className="flex-1"></div>
            </div>
            <header className="mb-2 flex flex-row items-center justify-between self-stretch">
              <div>
                <h2 className="mb-1  text-sm font-bold text-blue-500">
                  Alternate Contact
                </h2>
              </div>
            </header>
            <div className="flex flex-row gap-4">
              {renderField("Email", "alternate_email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "alternate_mobile_number",
                "",
                false,
                "flex-1"
              )}
              {renderField(
                "Secondary Phone Number",
                "alternate_secondary_phone",
                "",
                false,
                "flex-1"
              )}
            </div>
          </div>
        )}
      </section>

      <div className="mt-3 flex w-full flex-col space-y-2">
        <div>
          <CheckBoxComponent
            checked={!!data?.is_displaced}
            label="Are you currently displaced?"
            change={onDisplaced}
          ></CheckBoxComponent>
        </div>
        {!!data?.is_displaced && (
          <div className="flex flex-col space-y-3">
            <div className="flex items-center justify-between border border-black">
              <div className="mt-3">
                <h2 className="mb-1  text-sm font-bold text-blue-500">
                  DISPLACED ADDRESS
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500">
                  Last Edited:{" "}
                  {formatLastEditedTime(data?.d_updated_at?.toDate() || null)}
                </p>
              </div>
              <div className="flex items-center gap-2">
                {displacedEditMode ? (
                  <>
                    <button
                      type="button"
                      className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                      onClick={onSaveDisplacedDetails}
                    >
                      <FaSave className="h-3 w-3" />
                      Save
                    </button>
                    <button
                      type="button"
                      className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                      onClick={onEditDisplacedInfo(false)}
                    >
                      <FaTimes className="h-3 w-3" />
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                    onClick={onEditDisplacedInfo(true)}
                  >
                    <FaEdit className="h-3 w-3" />
                    Edit
                  </button>
                )}
                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
                  onClick={toggleDisplacedInfo}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isDisplacedInfoVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronDown />
                  </span>
                </button>
              </div>
            </div>
            {isDisplacedInfoVisible && (
              <>
                <div className="flex space-x-3">
                  {renderDisplacedField(
                    "Address 1",
                    "d_address_1",
                    "",
                    false,
                    "flex-1"
                  )}
                </div>
                <div className="flex">
                  {renderDisplacedField(
                    "Address 2",
                    "d_address_2",
                    "",
                    false,
                    "flex-1"
                  )}
                </div>
                <div className="flex space-x-3">
                  {renderDisplacedField("City", "d_city", "", false, "flex-1")}
                  {renderDisplacedField("State", "d_state", "", true, "flex-1")}
                  {renderDisplacedField("Zip", "d_zip", "", false, "flex-1")}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Household Information */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              HOUSEHOLD INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastHouseholdEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isHouseholdEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveHouseholdChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelHouseholdEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startHouseholdEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdInfoVisible && (
          <div className="mt-2 flex flex-col gap-4 self-stretch">
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Annual Household Income",
                "annual_income",
                "",
                false,
                "flex-1",
                true // Mark as optional
              )}

              <div className="flex flex-1 flex-row gap-4">
                <div className="flex-1">
                  <p className="text-gray-600 mb-1 pb-3 pt-1  text-xs">
                    Does anyone have a disability?
                    {isHouseholdEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4 ">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="disability"
                        value="Yes"
                        checked={tempHouseholdFormData.disability === true}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleHouseholdInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="disability"
                        value="No"
                        checked={tempHouseholdFormData.disability === false}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleHouseholdInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 mb-1 pb-3 pt-1  text-xs">
                    Active Military / Vet in the household
                    {isHouseholdEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="military_vet"
                        value="Yes"
                        checked={tempHouseholdFormData.military_vet === true}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleHouseholdInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="military_vet"
                        value="No"
                        checked={tempHouseholdFormData.military_vet === false}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleHouseholdInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Owner or Renter?",
                "owner_or_renter",
                "Select Owner or Renter",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Do you have insurance?",
                "insurance",
                "Select Yes or No",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Household size",
                "household_size",
                "",
                false,
                "flex-1"
              )}
            </div>
          </div>
        )}
      </section>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              ADDITIONAL HOUSEHOLD MEMBERS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {formatLastEditedTime(lastHouseholdMembersEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddClick}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdMembers}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdMembersVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdMembersVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={filteredHouseholdMembers}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionComplete={actionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="household_member_id"
                  headerText="household_member_id"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="date_of_birth"
                  headerText="Date of Birth"
                  width="100"
                  format="MM/dd/yyyy"
                  editTemplate={(props) => (
                    <DatePickerTemplate
                      label="Date of Birth *"
                      onChange={(val) => {
                        setDob(() => val);
                      }}
                      value={props.date_of_birth}
                    />
                  )}
                />
                <ColumnDirective
                  field="relationship"
                  headerText="Relationship"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="disability"
                  headerText="Disability"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={militaryvetParams}
                />
                <ColumnDirective
                  field="military_vet"
                  headerText="Active Military/ Vet"
                  width="100"
                  editType="dropdownedit"
                  edit={militaryvetParams}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>

      {/* Pets Section */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">PETS</h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastPetsEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddPet}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={togglePets}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPetsVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPetsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={petsGridRef}
              dataSource={filteredPets}
              keyField="pet_id"
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionComplete={petsActionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="pet_id"
                  headerText="Pet ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="pet_name"
                  headerText="Pet Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_color"
                  headerText="Pet Color"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_breed"
                  headerText="Pet Breed"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_size"
                  headerText="Pet Size"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>
      {/* Survey Section */}
      <section
        className={`mt-4 box-border flex w-full max-w-[1628px] flex-col items-start justify-start gap-2.5 overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white px-[19px] py-[18px] ${className}`}
      >
        <header
          id="survey-questions-header"
          className="sticky top-0 z-[99] flex w-full flex-row items-center justify-between bg-white"
        >
          <h2 className="mb-1  text-sm font-bold text-blue-500">
            SAMPLE SURVEY QUESTIONS
          </h2>
          <div className="flex items-center gap-2.5">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-md border border-dodgerblue px-[19px] py-1 text-xs text-dodgerblue"
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faArrowDown} className="h-4 w-4" />
              Jump to bottom
            </button>
            <button
              type="button"
              className="flex items-center gap-2 rounded-md bg-dodgerblue px-5 py-1.5 text-xs text-white"
              onClick={handleSubmitSurvey} // Call the submit function
            >
              <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
              Submit Survey
            </button>
          </div>
        </header>

        <main className="flex w-full flex-wrap gap-4  text-xs text-darkslategray-100">
          {surveyQuestions.map((question, index) => (
            <div
              key={index}
              className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
            >
              <SurveyQuestion
                question={question.question}
                type={question.type}
                options={question.options || []}
                onResponseChange={handleResponseChange}
                userResponse={responses[question.question] || ""}
              />
            </div>
          ))}
        </main>
        <footer
          id="survey-questions-footer"
          className="sticky bottom-0 z-[99] flex w-full flex-row items-center justify-end bg-white"
        >
          <div className="flex items-center gap-2.5">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-md border border-dodgerblue px-[19px] py-1 text-xs text-dodgerblue"
              onClick={scrollToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} className="h-4 w-4" />
              Jump to top
            </button>
            <button
              type="button"
              className="flex items-center gap-2 rounded-md bg-dodgerblue px-5 py-1.5 text-xs text-white"
              onClick={handleSubmitSurvey} // Call the submit function
            >
              <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
              Submit Survey
            </button>
          </div>
        </footer>
      </section>
    </form>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default ImmediateResponseSurvey;
