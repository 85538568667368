import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./style.css";

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const LocalNonProfitCoalition = ({ className = "" }) => {
  const [localNPC, setlocalNPC] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!userId) return; // Ensure userId is not null

      // Fetch the current user document to get center_id
      const userDocRef = doc(db, "users", userId); // Create a DocumentReference
      const userDoc = await getDoc(userDocRef); // Use getDoc instead of getDocs
      const userData = userDoc.data();
      const centerId = userData.center_id;

      // Fetch contacts from "center-local-npc" where user_id matches centerId
      const querySnapshot = await getDocs(
        query(
          collection(db, "center-local-npc"),
          where("user_id", "==", centerId)
        )
      );
      const contactsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setlocalNPC(preprocessData(contactsData));
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchContacts();
      } else {
        setUserId(null);
        setlocalNPC([]);
      }
    });

    return () => unsubscribe();
  }, [userId]); // Add userId as a dependency

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-2 text-xs text-darkslateblue">
            <a className="font-medium">My Local Community</a>
            <span className="text-base font-semibold text-gray-400">/</span>
            <span className="font-medium">Local Non Profit Coalition</span>
          </div>
        </div>
      </div>
      <div className="ml-3 flex flex-col items-start justify-start">
        <b className="text-md relative leading-[21px]">
          LOCAL NON PROFIT COALITION
        </b>
        <div className="relative text-sm font-medium leading-[21px]">
          The contact information will automatically populate with the details
          provided by a certified Center. Additionally, it's important to know
          that the Center has identified specific nonprofits that are equipped
          to provide essential services in the aftermath of a disaster.
        </div>
        <div className="relative text-xs leading-[21px] text-darkgray">
          Last Edited: {formatLastEditedTime(lastEditedTime)}
        </div>
        <section className="w-full rounded-3xs pb-5 pr-7 pt-[14px] text-left text-xs text-darkslateblue">
          <div>
            <GridComponent
              dataSource={localNPC}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowSorting={true}
              ref={gridRef}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="npcID"
                  headerText="npcID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="primaryCounty"
                  headerText="Primary County"
                  width="150"
                  visible={true}
                />
                <ColumnDirective
                  field="organization"
                  headerText="Organization"
                  visible={true}
                  width="150"
                />
                <ColumnDirective
                  field="primaryServiceProvided"
                  headerText="Primary Service Provided"
                  width="150"
                />
                <ColumnDirective
                  field="mobileNumber"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="150"
                />
                <ColumnDirective
                  field="Address"
                  headerText="Address"
                  width="250"
                />
                <ColumnDirective
                  field="address1"
                  headerText="Address 1"
                  visible={false}
                />
                <ColumnDirective
                  field="address2"
                  headerText="Address 2"
                  visible={false}
                />
                <ColumnDirective
                  field="city"
                  headerText="City"
                  visible={false}
                />
                <ColumnDirective
                  field="state"
                  headerText="State"
                  visible={false}
                />
                <ColumnDirective
                  field="zip"
                  headerText="Zip Code"
                  visible={false}
                />
              </ColumnsDirective>
              <Inject services={[Filter, Sort, Page, CommandColumn]} />
            </GridComponent>
          </div>
        </section>
      </div>
    </>
  );
};

export default LocalNonProfitCoalition;
