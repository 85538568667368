import React, { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../pages/Disasters/DisasterContext";
import DisasterManagement from "./Disasters/DisasterManagement";
import AssistanceLog from "./Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./Disasters/RecoveryPlan";
import Journals from "./Disasters/Journals";
import ContactsDisaster from "./Disasters/ContactsDisaster";
import ImmediateResponseSurvey from "./Disasters/ImmediateResponseSurvey";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const Disasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");

  const [selectedDisasters, setSelectedDisasters] = useState(["All"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([{ id: "All", name: "All Disasters" }]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status
        }));
        
        const activeDisasterIds = fetchedDisasters
          .filter(disaster => disaster.status === "active")
          .map(disaster => disaster.id);
        
        setActiveDisasters(activeDisasterIds);
        setDisasterOptions([
          { id: "All", name: "All Disasters" },
          ...fetchedDisasters.filter(disaster => disaster.status === "active")
        ]);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start px-2.5 py-[15px] ${className}`}
    >
      <div className="mb-4 flex flex-row items-center justify-start">
        <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Recovery Plan
          </a>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Disasters
          </a>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <span className="relative font-medium leading-[16px]">
            {activeDisasterTab}
          </span>
        </div>
      </div>

      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        <div className="relative flex items-center gap-2" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-500">DISASTERS</span>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm w-[450px] relative"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.includes("All") 
                    ? "All Disasters" 
                    : selectedDisasters.length > 0
                      ? disasterOptions
                          .filter(disaster => selectedDisasters.includes(disaster.id))
                          .map(disaster => disaster.name)
                          .join(", ")
                      : "Select Disasters"}
                </span>
              </span>
              <span className="ml-auto">▼</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
                {disasterOptions.map((disaster) => (
                  <label key={disaster.id} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedDisasters.includes(disaster.id)}
                      onChange={() => handleDisasterChange(disaster.id)}
                      className="mr-2"
                    />
                    {disaster.name}
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Tabs */}
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="relative mt-4 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        {/* <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p> */}
      </div>

      {activeDisasterTab === "Immediate Response Survey" && (
        <ImmediateResponseSurvey />
      )}
      {activeDisasterTab === "Damage Assessment" && (
        <DisasterManagement 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Assistance Log" && (
        <AssistanceLog selectedDisasters={selectedDisasters} 
        activeDisasters={activeDisasters} />
      )}
      {activeDisasterTab === "Recovery Plan" && (
        <RecoveryPlanDisasters 
          selectedDisasters={selectedDisasters} 
          activeDisasters={activeDisasters}
        />
      )}
      {activeDisasterTab === "Contacts" && <ContactsDisaster />}
      {activeDisasterTab === "Journals" && <Journals />}
    </div>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default Disasters;
