import React, { useState } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  doc,
  setDoc,
  addDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import mailer from "../service/api/mailer";
import { countries } from "./datasource";
import { USStates } from "./datasource";
import countiesData from "../counties_list.json";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import bxcheck from "../../src/assets/bx-check.svg";
import bxclose from "../../src/assets/bx-close.svg";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
const userType = [
  { value: "Citizen" },
  { value: "Center" },
  { value: "Partner" },
];

const RegisterV2 = () => {
  const [formData, setFormData] = useState({
    userType: "Citizen",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    mobile_number: "",
    password: "",
    country: "US",
    county: "",
    name_of_org: "",
    name_of_hub: "",
    state_or_national_nonprofit: "",
    a501c3: "",
    ein: "",
    area_serviced_county: "",
  });
  const [selectedState, setSelectedState] = useState("");
  const [missingFields, setMissingFields] = useState([]);
  const [counties, setCounties] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  //Partner
  //const [a501c3, seta501c3] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPW, setConfirmPW] = useState("");
  const [agree, setAgree] = useState(false);
  const passwordsMatch = formData.password === confirmPW;
  const isValidLength = formData.password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(formData.password);
  const hasNumberOrSpecialChar = /[0-9!@#$%^&*(),.?":{}|<>]/.test(
    formData.password
  );

  const { login, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    ); // Find the state object
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData({ ...formData, state: stateAbbreviation }); // Store full state name

    // Filter counties based on selected state
    const filteredCounties = countiesData.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  const handleCountyChange = (e, isPartner) => {
    if (isPartner) {
      setFormData({
        ...formData,
        area_serviced_county: e.target.value, // Update area_serviced_county in formData
      });
    } else {
      setFormData({
        ...formData,
        county: e.target.value, // Update county in formData
      });
    }
    if (isPartner && !e.target.value) {
      setMissingFields([...missingFields, "area_serviced_county"]);
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    let requiredFields = [];
    switch (formData.userType) {
      case "Center":
        requiredFields = [
          "email",
          "first_name",
          "last_name",
          "address1",
          "city",
          "state",
          "zip",
          "county",
          "country",
          "mobile_number",
          "name_of_hub",
        ];
        break;
      case "Partner":
        requiredFields = [
          "name_of_org",
          "email",
          "first_name",
          "last_name",
          "mobile_number",
          "state",
          "area_serviced_county",
          // "county",
          "state_or_national_nonprofit",
          "a501c3",
        ];
        break;
      case "Citizen":
        requiredFields = [
          "email",
          "first_name",
          "last_name",
          "address1",
          "city",
          "state",
          "zip",
          "county",
          "country",
          "mobile_number",
        ];
        break;
      default:
        requiredFields = [
          "email",
          "first_name",
          "last_name",
          "address1",
          "city",
          "state",
          "zip",
          "county",
          "country",
          "mobile_number",
        ];
    }

    const missing = requiredFields.filter((field) => !formData[field]);

    // Check if EIN is required when a501c3 is "Yes"
    if (formData.a501c3 === "Yes" && !formData.ein) {
      missing.push("ein");
    }
    setMissingFields(missing);

    if (missing.length > 0) {
      return;
    }

    setCurrentStep(2);
  };

  const handleContinue2 = async (e) => {
    e.preventDefault();
    await handleSignup();
    // setCurrentStep(3);
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    // window.location.reload();
    return !querySnapshot.empty; // Returns true if email exists
  };

  const getCenterIdByCounty = async (county) => {
    const centersRef = collection(db, "centers");
    const q = query(centersRef, where("county", "==", county));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    return "";
  };

  const handleSignup = async (e) => {
    try {
      if (formData.password !== confirmPW) {
        alert("Password does not match!");
        return;
      }

      if (!agree) {
        alert("Check Privacy Policy and Terms and Conditions.");
        return;
      }

      const emailExists = await checkIfEmailExists(formData.email);

      if (emailExists) {
        alert("Email already exists. Please use a different email or log in.");
        return;
      }

      const { email, password, first_name, last_name, ...otherData } = formData;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      let centerId = null;
      const otp = generateOtp();
      const status = "registered";
      let user_role = "citizen";
      let centersRef;
      let centerDocRef;
      let employeeSubcollectionRef;
      let organizationSubcollectionRef;
      let partnersRef;
      let partnersDofRef;
      let partnerId = null;

      switch (formData.userType) {
        case "Center":
          user_role = "center";
          // create center
          centersRef = collection(db, "centers");
          centerDocRef = await addDoc(centersRef, {
            ...otherData,
            email,
            first_name,
            last_name,
            profile_completed: false,
          });
          centerId = centerDocRef.id;
          // create user
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password,
            first_name,
            last_name,
            otp,
            status,
            user_role,
            center_id: centerId,
            citizen_id: userCredential.user.uid,
            profile_completed: false,
          });
          // create employee under center
          employeeSubcollectionRef = collection(
            db,
            `centers/${centerId}/employees`
          );
          await setDoc(doc(employeeSubcollectionRef, userCredential.user.uid), {
            user_id: userCredential.user.uid,
            email,
            first_name,
            last_name,
            is_admin: true,
          });
          // create organization under users
          organizationSubcollectionRef = collection(
            db,
            `users/${userCredential.user.uid}/organizations`
          );
          await setDoc(doc(organizationSubcollectionRef, centerId), {
            org_type: "center",
            org_id: centerId,
            role: "center",
            is_admin: true,
            email,
            first_name,
            last_name,
            ...otherData,
          });
          break;
        case "Partner":
          user_role = "partner";
          centerId = await getCenterIdByCounty(formData.county);
          // create partner
          partnersRef = collection(db, "partners");
          partnersDofRef = await addDoc(partnersRef, {
            ...otherData,
            email,
            first_name,
            last_name,
            profile_completed: false,
          });
          partnerId = partnersDofRef.id;
          // create user
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password,
            first_name,
            last_name,
            otp,
            status,
            user_role,
            center_id: ["rRWB11eeLXXxzeZ6jWGyXMxxWCD2"],
            citizen_id: userCredential.user.uid,
            partner_id: partnerId,
            profile_completed: false,
          });
          // create employee under partner
          employeeSubcollectionRef = collection(
            db,
            `partners/${partnerId}/employees`
          );
          await setDoc(doc(employeeSubcollectionRef, userCredential.user.uid), {
            user_id: userCredential.user.uid,
            email,
            first_name,
            last_name,
            is_admin: true,
          });
          // create organization under users
          organizationSubcollectionRef = collection(
            db,
            `users/${userCredential.user.uid}/organizations`
          );
          await setDoc(doc(organizationSubcollectionRef, partnerId), {
            org_type: "partner",
            org_id: partnerId,
            role: "partner",
            is_admin: true,
            email,
            first_name,
            last_name,
            ...otherData,
          });
          break;
        case "Citizen":
          user_role = "citizen";
          centerId = await getCenterIdByCounty(formData.county);
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password,
            first_name,
            last_name,
            otp,
            status,
            user_role,
            center_id: "rRWB11eeLXXxzeZ6jWGyXMxxWCD2",
            citizen_id: userCredential.user.uid,
            profile_completed: false,
            ...otherData,
          });
          break;
        default:
          user_role = "citizen";
          centerId = await getCenterIdByCounty(formData.county);
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            first_name,
            last_name,
            otp,
            status,
            user_role,
            center_id: "rRWB11eeLXXxzeZ6jWGyXMxxWCD2",
            citizen_id: userCredential.user.uid,
            ...otherData,
          });
      }

      await mailer.sendOtpEmail(email, otp, first_name);

      const creds = await signInWithEmailAndPassword(auth, email, password);

      setRoleBasedRoute(user_role);
      login(creds.user.accessToken);

      localStorage.setItem("newlyRegistered", "true");
      navigate("/register2");
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        alert("This email is already registered.");
      } else if (err.code === "auth/invalid-email") {
        alert("Invalid email format.");
      } else if (err.code === "auth/weak-password") {
        alert("Password is too weak.");
      } else {
        alert(err.message);
      }
    }
  };

  return (
    <div className="viewport h-auto w-full">
      <div className="viewport-bg flex min-h-screen w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13] pb-2 pt-2">
        <div className="flex  rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
          <div className="  w-[350px] rounded-l-lg bg-white  pb-2">
            <h5 className="mt-12 flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label
                className="mt-2.5 "
                style={{ fontSize: "32px", fontWeight: 700 }}
              >
                DARCI
              </label>
            </h5>
            <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none">
                  <span className="text-base">Powered by </span>
                  <span className="text-base text-blue-500">BlueSky</span>
                </div>
                <div className="mt-1.5 text-xs">Step {currentStep} of 3</div>
                <div className="ml-1 mt-1.5 text-base leading-none">
                  Create account as a
                  <span className="ml-1 text-blue-500">{`${formData.userType}`}</span>
                </div>
              </div>
            </div>

            <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center px-5">
              <div className="flex w-full flex-col gap-[5px] py-3">
                {currentStep === 1 && (
                  <form className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
                    <div className="flex w-full flex-col gap-[5px] pt-3">
                      <div>
                        <DropDownListComponent
                          floatLabelType="Auto"
                          dataSource={userType}
                          fields={{ text: "value", value: "value" }}
                          value={formData.userType}
                          name="userType"
                          change={(e) =>
                            handleChange({
                              target: { name: "userType", value: e.value }, // Ensure name is "userType"
                            })
                          }
                          cssClass="user-type-dropdown e-outline"
                        />
                      </div>
                      {formData.userType === "Citizen" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <TextBoxComponent
                              placeholder="Mobile Number"
                              floatLabelType="Auto"
                              name="mobile_number"
                              cssClass="e-outline"
                              value={formData.mobile_number}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "mobile_number",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("mobile_number") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Email Address"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="email"
                              value={formData.email}
                              change={(e) =>
                                handleChange({
                                  target: { name: "email", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("email") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div>
                            <DropDownListComponent
                              placeholder="Select a country"
                              floatLabelType="Auto"
                              dataSource={countries}
                              fields={{ text: "name", value: "abbreviation" }}
                              value={formData.country}
                              name="country"
                              change={(e) =>
                                handleChange({
                                  target: { name: "country", value: e.value },
                                })
                              }
                              cssClass="e-outline"
                            />
                            {missingFields.includes("country") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 1"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address1"
                              value={formData.address1}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address1", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("address1") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 2"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address2"
                              value={formData.address2}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address2", value: e.value },
                                })
                              }
                            />
                          </div>
                          <div className="flex gap-2">
                            <div className="w-[30%]">
                              <TextBoxComponent
                                placeholder="City"
                                floatLabelType="Auto"
                                name="city"
                                cssClass="e-outline"
                                value={formData.city}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "city", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("city") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="flex-1">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={selectedState}
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="State"
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-[21%]">
                              <TextBoxComponent
                                placeholder="Zip"
                                floatLabelType="Auto"
                                name="zip"
                                cssClass="e-outline"
                                value={formData.zip}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "zip", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("zip") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <DropDownListComponent
                              dataSource={counties}
                              fields={{ text: "County", value: "County" }}
                              value={formData.county}
                              change={(e) =>
                                handleCountyChange({
                                  target: { value: e.value },
                                })
                              }
                              enabled={!!selectedState}
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              placeholder="County"
                            />
                            {missingFields.includes("county") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {formData.userType === "Partner" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Email Address"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="email"
                              value={formData.email}
                              change={(e) =>
                                handleChange({
                                  target: { name: "email", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("email") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Name of Organization"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="name_of_org"
                              value={formData.name_of_org}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "name_of_org",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("name_of_org") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={selectedState}
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="Area Serviced (State)"
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <MultiSelectComponent
                                dataSource={counties}
                                fields={{ text: "County", value: "County" }}
                                value={formData.area_serviced_county}
                                change={(e) =>
                                  handleCountyChange(
                                    { target: { value: e.value } },
                                    true
                                  )
                                }
                                enabled={!!selectedState}
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="Area Serviced (County)"
                                mode="CheckBox"
                                selectAllText="Select All"
                                unSelectAllText="Unselect All"
                                showSelectAll={true}
                              >
                                <Inject services={[CheckBoxSelection]} />
                              </MultiSelectComponent>
                              {missingFields.includes(
                                "area_serviced_county"
                              ) && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Contact Person First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Contact Person Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <TextBoxComponent
                              placeholder="Contact Number"
                              floatLabelType="Auto"
                              name="mobile_number"
                              cssClass="e-outline"
                              value={formData.mobile_number}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "mobile_number",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("mobile_number") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <DropDownListComponent
                              dataSource={yesNoOptions}
                              fields={{ text: "text", value: "value" }}
                              placeholder="Are you a state or a national nonprofit"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "state_or_national_nonprofit",
                                    value: e.value,
                                  },
                                })
                              }
                              value={formData.state_or_national_nonprofit}
                            />
                            {missingFields.includes(
                              "state_or_national_nonprofit"
                            ) && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <DropDownListComponent
                              dataSource={yesNoOptions}
                              fields={{ text: "text", value: "value" }}
                              placeholder="Are you a 501c3"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              change={(e) =>
                                handleChange({
                                  target: { name: "a501c3", value: e.value },
                                })
                              }
                              value={formData.a501c3}
                            />
                            {missingFields.includes("a501c3") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          {formData.a501c3 === "Yes" && (
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="EIN (Numbers Only)"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                type="number"
                                name="ein"
                                value={formData.ein}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "ein", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("ein") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {formData.userType === "Center" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div>
                            <TextBoxComponent
                              placeholder="Name of Organization"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="name_of_hub"
                              value={formData.name_of_hub}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "name_of_hub",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("name_of_hub") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div>
                            <DropDownListComponent
                              placeholder="Select a country"
                              floatLabelType="Auto"
                              dataSource={countries}
                              fields={{ text: "name", value: "abbreviation" }}
                              value={formData.country}
                              name="country"
                              change={(e) =>
                                handleChange({
                                  target: { name: "country", value: e.value },
                                })
                              }
                              cssClass="e-outline"
                            />
                            {missingFields.includes("country") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 1"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address1"
                              value={formData.address1}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address1", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("address1") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 2"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address2"
                              value={formData.address2}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address2", value: e.value },
                                })
                              }
                            />
                          </div>
                          <div className="flex gap-2">
                            <div className="w-[25%]">
                              <TextBoxComponent
                                placeholder="City"
                                floatLabelType="Auto"
                                name="city"
                                cssClass="e-outline"
                                value={formData.city}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "city", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("city") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="flex-1">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={selectedState}
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="State"
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-[21%]">
                              <TextBoxComponent
                                placeholder="Zip"
                                floatLabelType="Auto"
                                name="zip"
                                cssClass="e-outline"
                                value={formData.zip}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "zip", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("zip") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="flex-1">
                              <DropDownListComponent
                                dataSource={counties}
                                fields={{ text: "County", value: "County" }}
                                value={formData.county}
                                change={(e) =>
                                  handleCountyChange({
                                    target: { value: e.value },
                                  })
                                }
                                enabled={!!selectedState}
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="County"
                              />
                              {missingFields.includes("county") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Email Address"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="email"
                                value={formData.email}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "email", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("email") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Contact Number"
                                floatLabelType="Auto"
                                name="mobile_number"
                                cssClass="e-outline"
                                value={formData.mobile_number}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "mobile_number",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("mobile_number") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex max-w-[483px] flex-col justify-center tracking-wide">
                        <div className="max-md:max-w-full flex w-full flex-col justify-center text-center">
                          <button
                            className="max-md:max-w-full w-full gap-2.5 self-stretch whitespace-nowrap rounded-md bg-blue-500 p-2.5 text-xs font-bold text-white"
                            onClick={handleContinue}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {currentStep === 3 && (
                  <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
                    <h2 className="text-xl max-md:max-w-full font-semibold leading-tight text-slate-600">
                      Enter Your Confirmation Code
                    </h2>
                    <section className="max-md:max-w-full mt-2 mt-2.5 flex w-full flex-col justify-center">
                      <p className="font-small max-md:max-w-full text-slate-600">
                        Please enter the confirmation code sent to your email.
                      </p>
                      <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                        <input
                          type="text"
                          aria-label="Confirmation code"
                          className="max-md:max-w-full my-auto flex min-h-[40px] w-full min-w-[240px] flex-1 shrink basis-0 self-stretch rounded-lg border-2 border-solid border-gray-300 bg-white"
                        />
                      </div>
                      <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                        <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                          You have entered an invalid code!
                        </div>
                        <button className="my-auto w-[121px] self-stretch text-right text-blue-600">
                          Resend Code
                        </button>
                      </div>
                      <div
                        className="flex max-w-[483px] flex-col justify-center tracking-wide"
                        style={{ marginTop: "40px" }}
                      >
                        <div className="text-gray-700 max-md:max-w-full flex w-full flex-col items-start text-base leading-none">
                          <div className="flex w-full gap-0.5">
                            <input
                              type="checkbox"
                              id="privacyPolicyCheckbox"
                              className="h-5 min-h-[20px] w-5 rounded border border-solid border-zinc-300"
                            />
                            <label
                              htmlFor="privacyPolicyCheckbox"
                              className="text-gray-700 z-10 mr-0 min-h-[21px] flex-auto gap-2.5 self-stretch px-2.5 py-0.5 text-base leading-none"
                            >
                              I agree with{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/privacy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/terms"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                        <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                          <button className="max-md:max-w-full w-full gap-2.5 self-stretch whitespace-nowrap rounded-md bg-blue-500 p-2.5 text-xs font-bold text-white">
                            Create an Account
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                )}

                {currentStep === 2 && (
                  <form className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
                    <div className="flex w-full flex-col gap-[5px] pt-3">
                      {formData.userType === "Citizen" && (
                        <div className="mt-4 flex">
                          <div>
                            <img
                              loading="lazy"
                              src={userIcon}
                              className="aspect-[0.99] w-[40px] object-contain"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                              {formData.email}
                            </p>
                            <p
                              className="max-md:max-w-full mt-2 whitespace-nowrap"
                              style={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {formData.last_name
                                ? formData.last_name
                                : "Last Name not provided"}
                              ,{" "}
                              {formData.first_name
                                ? formData.first_name
                                : "First Name not provided"}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="relative mt-2 w-full">
                        <TextBoxComponent
                          placeholder="Password"
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          change={(e) =>
                            handleChange({
                              target: { name: "password", value: e.value },
                            })
                          }
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                        {missingFields.includes("password") && (
                          <span className="text-xs text-red-500">Required</span>
                        )}
                      </div>
                      <div className="relative w-full">
                        <TextBoxComponent
                          placeholder="Confirm Password"
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPW}
                          change={(e) => setConfirmPW(e.value)}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-start text-xs font-semibold">
                          Your password must include:
                        </p>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            isValidLength && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              isValidLength && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 8 characters</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            hasUpperCase && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              hasUpperCase && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 1 uppercase letter</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            hasNumberOrSpecialChar && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              hasNumberOrSpecialChar && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 1 number or special character</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            passwordsMatch && formData.password && confirmPW
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              passwordsMatch && formData.password && confirmPW
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>Passwords match</p>
                        </div>
                      </div>
                      <div
                        className="flex max-w-[483px] flex-col justify-center tracking-wide"
                        style={{ marginTop: "40px" }}
                      >
                        <div className="text-gray-700 max-md:max-w-full flex w-full flex-col items-start text-base leading-none">
                          <div className="flex w-full gap-0.5">
                            <input
                              type="checkbox"
                              id="privacyPolicyCheckbox"
                              name="privacyPolicyCheckbox"
                              className="h-5 min-h-[20px] w-5 rounded border border-solid border-zinc-300"
                              value={agree}
                              onChange={(e) => setAgree(e.target.checked)}
                            />
                            <label
                              htmlFor="privacyPolicyCheckbox"
                              className="text-gray-700 z-10 mr-0 min-h-[21px] flex-auto gap-2.5 self-stretch px-2.5 py-0.5 text-base leading-none"
                            >
                              I agree with{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/privacy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/terms"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                        <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                          <button
                            className="max-md:max-w-full w-full gap-2.5 self-stretch whitespace-nowrap rounded-md bg-blue-500 p-2.5 text-xs font-bold text-white"
                            onClick={handleContinue2}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col gap-[5px]">
                    <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                      <div className="self-center text-base leading-none text-black">
                        <span className="text-gray-700">
                          Already have an account?
                        </span>{" "}
                        <a href="/login" className="text-blue-500">
                          Login
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="     block   w-[350px] rounded-r-lg bg-blue-500 p-4 leading-4 mq700:hidden  ">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, powered by Blue Sky.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterV2.propTypes = {
  className: PropTypes.string,
};

export default RegisterV2;
