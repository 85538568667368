import React from "react";
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  Inject,
  PieSeries,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingColumnSeries,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  Highlight,
  AccumulationSelection,
  AccumulationAnnotation,
  ChartAnnotation,
  AccumulationAnnotationDirective,
  AccumulationAnnotationsDirective,
  ColumnSeries,
  BarSeries,
} from "@syncfusion/ej2-react-charts";

// Doughnut Component
const Doughnut = ({ title, chartData, total, totalorder }) => {
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <AccumulationChartComponent
          id={title}
          legendSettings={{
            visible: true,
            toggleVisibility: false,
            position: "Right",
            height: "50%",
            width: "20%",
            textWrap: "Wrap",
            maximumLabelWidth: 66,
          }}
          enableSmartLabels={true}
          enableAnimation={false}
          selectionMode={"Point"}
          center={{ x: "50%", y: "50%" }}
          enableBorderOnMouseMove={false}
          tooltip={{
            enable: true,
            header: "",
          }}
          height="230px"
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationDataLabel,
              AccumulationTooltip,
              AccumulationSelection,
              ChartAnnotation,
              AccumulationAnnotation,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              explode={false}
              explodeOffset="10%"
              explodeIndex={0}
              startAngle={30}
              innerRadius="43%"
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: { fontWeight: "600", color: "#ffffff" },
                connectorStyle: { length: "20px", type: "Curve" },
              }}
              radius="80%"
            />
          </AccumulationSeriesCollectionDirective>
          <AccumulationAnnotationsDirective>
            <AccumulationAnnotationDirective
              content={""}
              region="Series"
              x="52%"
              y="50%"
            />
          </AccumulationAnnotationsDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  );
};

// ColumnChart Component
const ColumnChart = ({ title, chartData, total, totalorder }) => {
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            labelIntersectAction: "None",
            labelRotation: 0,
            valueType: "Category",
            interval: 1,
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            title: "Population %",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximum: 40,
            interval: 10,
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
            shared: true,
          }}
          width="100%"
          height="230px"
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              Category,
              DataLabel,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              columnSpacing={0.1}
              yName="y"
              name={title}
              type="Column"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

// StackingChart Component
const StackingChart = ({ title, chartData, total, totalorder }) => {
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate45",
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            rangePadding: "None",
            interval: 20000,
            majorTickLines: { width: 0 },
            majorGridLines: { width: 1 },
            minorGridLines: { width: 1 },
            minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          width={"100%"}
          height="250px"
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
          }}
        >
          <Inject
            services={[
              StackingColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              Category,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              name="Lowest Income"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y1"
              name="Lower Middle Income"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y2"
              name="Upper Middle Income"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y3"
              name="Highest Income"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

// BarChart Component
const BarChart = ({ title, chartData, total, totalorder }) => {
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{ valueType: "Category", majorGridLines: { width: 0 } }}
          primaryYAxis={{
            labelFormat: "{value}%",
            edgeLabelPlacement: "Shift",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          chartArea={{ border: { width: 0 } }}
          width="100%"
          height="230px"
          tooltip={{ enable: true }}
        >
          <Inject
            services={[
              BarSeries,
              DataLabel,
              Category,
              Legend,
              Tooltip,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              type="Bar"
              columnSpacing={0.1}
              name={title}
              width={2}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

// Demographics Component
const Demographics = () => {
  // Updated datasets for each demographic category, ensuring totals sum to 5,000.00
  const genderData = [
    { x: "Male", y: 2400, text: "48%" },
    { x: "Female", y: 2500, text: "50%" },
    { x: "Other", y: 100, text: "2%" },
  ];

  const ageData = [
    { x: "0-18", y: 1350 },
    { x: "19-29", y: 1300 },
    { x: "30-44", y: 1750 },
    { x: "45-60", y: 750 },
    { x: "60+", y: 350 },
  ];

  const incomeData = [
    { x: "Low Income", y: 1250 },
    { x: "Middle Income", y: 2500 },
    { x: "High Income", y: 750 },
    { x: "Very High Income", y: 500 },
  ];

  const insuredData = [
    { x: "Insured", y: 3500, text: "70%" },
    { x: "Non-Insured", y: 1500, text: "30%" },
  ];

  const disabilityData = [
    { x: "No Disability", y: 4250, text: "85%" },
    { x: "Disability", y: 750, text: "15%" },
  ];

  const militaryData = [
    { x: "No Military Household", y: 4500, text: "90%" },
    { x: "Military Household", y: 500, text: "10%" },
  ];

  const homeOwnershipData = [
    { x: "Homeowners", y: 3000, text: "60%" },
    { x: "Renters", y: 2000, text: "40%" },
  ];

  const ethnicityData = [
    { x: "White", y: 2750, text: "55%" },
    { x: "Black or African American", y: 1000, text: "20%" },
    { x: "Asian", y: 500, text: "10%" },
    { x: "Hispanic or Latino", y: 600, text: "12%" },
    { x: "Other", y: 150, text: "3%" },
  ];

  const educationData = [
    { x: "High School", y: 1250 },
    { x: "Associate's Degree", y: 750 },
    { x: "Bachelor's Degree", y: 1500 },
    { x: "Master's Degree", y: 1000 },
    { x: "Doctorate", y: 500 },
  ];

  return (
    <div className="relative flex h-full flex-col space-y-2 py-5 pl-3 pr-5">
      {/* Breadcrumb */}
      <div className="flex items-center space-x-2">
        <p className="text-[12px]">Recovery Plan</p>
        <p>/</p>
        <p className="text-[12px]">Demographics</p>
      </div>

      {/* Title */}
      <div>
        <h2 className="mb-1 mt-1 text-sm font-bold text-blue-500">
          Demographics
        </h2>
      </div>

      {/* Charts Grid */}
      <div className="flex flex-col space-y-2 rounded-3xs border-[1px] border-solid border-[#C9C9C9] p-[15px]">
        <div className="grid grid-cols-4 gap-x-2 gap-y-2">
          {/* Gender */}
          <Doughnut
            title="Gender"
            chartData={genderData}
            total={5000}
            totalorder={50}
          />

          {/* Age */}
          <ColumnChart
            title="Age"
            chartData={ageData}
            total={5000}
            totalorder={50}
          />

          {/* Income Levels */}
          <StackingChart
            title="Income Levels"
            chartData={incomeData}
            total={5000}
            totalorder={50}
          />

          {/* Insured vs Non-Insured Homeowners */}
          <Doughnut
            title="Insured vs Non-Insured Homeowners"
            chartData={insuredData}
            total={5000}
            totalorder={50}
          />

          {/* Disability */}
          <Doughnut
            title="Disability"
            chartData={disabilityData}
            total={5000}
            totalorder={50}
          />

          {/* Military in Household */}
          <Doughnut
            title="Military in Household"
            chartData={militaryData}
            total={5000}
            totalorder={50}
          />

          {/* Homeowners vs Renters */}
          <Doughnut
            title="Homeowners vs Renters"
            chartData={homeOwnershipData}
            total={5000}
            totalorder={50}
          />

          {/* Educational Attained */}
          <BarChart
            title="Educational Attained"
            chartData={educationData}
            total={5000}
            totalorder={50}
          />

          {/* Ethnicity */}
          <Doughnut
            title="Ethnicity"
            chartData={ethnicityData}
            total={5000}
            totalorder={50}
          />
        </div>
      </div>
    </div>
  );
};

export default Demographics;
