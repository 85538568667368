import PropTypes from "prop-types";
import { ReactComponent as LibraryIcon } from "../icons/home/library.svg";
import { ReactComponent as MapsIcon } from "../icons/home/maps.svg";
import { ReactComponent as JobOpportunitiesIcon } from "../icons/home/job-opportunities.svg";
import { ReactComponent as VolunteerOpportunitiesIcon } from "../icons/home/volunteer-opportunities.svg";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth"; // Import getAuth to access the current user
import { doc, getDoc, Query } from "firebase/firestore"; // Import getDoc and doc to fetch user data
import { useNavigate } from "react-router-dom";
import { ReactComponent as AnalyticsIcon } from "../icons/home/analytics.svg";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};

const CitizenHome = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]); // Initialize toolbar options
  const [userRole, setUserRole] = useState(""); // State to hold user role
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth(); // Get the authentication instance
      const user = auth.currentUser; // Retrieve the currently logged-in user
      if (user) {
        const userId = user.uid; // Get the UID of the logged-in user
        const userDoc = await getDoc(doc(db, "users", userId)); // Fetch user document
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role); // Set user role in state
          if (role === "citizen" || role === "partner") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]); // Remove toolbar options for citizen and partner
          }
        }
      }
    };

    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "declared-disaster"));
      const fetchedData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          disaster_id: doc.id,
          disaster_date: data.disaster_date
            ? data.disaster_date.toDate().toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
        };
      });
      setData(fetchedData); // Set the fetched data to state
      setLoading(false);
    };

    fetchUserRole();
    fetchData(); // Call fetchData to populate the grid
  }, []);

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const deletedItems = Array.isArray(args.data) ? args.data : [args.data];
      const deletedIds = deletedItems.map((item) => item.disaster_id);
      console.log("Deleting IDs:", deletedIds);
      const updatedData = data.filter(
        (item) => !deletedIds.includes(item.disaster_id)
      );
      setData(updatedData);
    } else if (args.requestType === "save") {
      console.log("Save action triggered", args.data);
      const updatedItem = args.data;
      const updatedData = data.map((item) =>
        item.disaster_id === updatedItem.disaster_id ? updatedItem : item
      );
      setData(updatedData);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      if (dialog) {
        // Check if dialog is not null
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Record of " + args.rowData["disasterID"]
            : "New Disaster";

        // Set the header styles
        const headerContent = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        const header = dialog.element.querySelector(".e-dlg-header");
        if (headerContent && header) {
          // Ensure elements exist before accessing
          headerContent.style.backgroundColor = "#348BFF";
          headerContent.style.color = "#FFFFFF";
          header.style.color = "#FFFFFF";
        }

        // Style the save and cancel buttons
        setTimeout(() => {
          const saveButton = dialog.element.querySelector(".e-primary");
          const cancelButton = dialog.element.querySelector(
            ".e-btn:not(.e-primary)"
          );

          if (saveButton) {
            saveButton.style.backgroundColor = "#FFFFFF";
            saveButton.style.color = "#348BFF";
            saveButton.style.border = "none";
          }

          if (cancelButton) {
            cancelButton.style.backgroundColor = "#FFFFFF";
            cancelButton.style.color = "#348BFF";
            cancelButton.style.border = "1px solid #348BFF";
          }
        }, 0);
      }
    } else if (args.requestType === "add") {
      const auth = getAuth(); // Get the authentication instance
      const user = auth.currentUser; // Retrieve the currently logged-in user
      if (user) {
        const newDisaster = {
          ...args.data,
          center_id: user.uid, // Save the logged-in user's UID to center_id
        };
        await addDoc(collection(db, "declared-disaster"), newDisaster); // Save new disaster to Firestore
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        ); // Refresh data
        const fetchedData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          disaster_id: doc.id,
        }));
        setData(fetchedData); // Update state with new data
      }
    }
  };

  const toolbarClick = (args) => {
    if (args.item.id === "Grid_add") {
    } else if (args.item.id === "Grid_edit") {
    } else if (args.item.id === "Grid_delete") {
    } else if (args.item.id === "Grid_update") {
    } else if (args.item.id === "Grid_cancel") {
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === "disaster_name") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_name}</div>`;
      }
    }

    if (args.column.field === "disaster_status") {
      let bgColor, textColor;
      switch (args.data.disaster_status) {
        case "Active":
          bgColor = "#DEF7EC"; // Green
          textColor = "#299D91"; // White
          break;
        case "Inactive":
          bgColor = "#FDE8E8"; // Red
          textColor = "#E4626F"; // White
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_status}</div>`;
      }
    }

    if (args.column.field === "alert_level") {
      let bgColor, textColor;
      switch (args.data.alert_level) {
        case "Level 1":
          bgColor = "#FFEDBC"; // Light Yellow
          textColor = "#F4B000"; // Dark Orange
          break;
        case "Level 2":
          bgColor = "#FAE2CC"; // Light Orange
          textColor = "#E46E00"; // Dark Orange
          break;
        case "Level 3":
          bgColor = "#F1D5D5"; // Light Red
          textColor = "#CD1A1A"; // Dark Red
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.alert_level}</div>`;
      }
    }
  };

  const intl = new Internationalization();

  const formatDate = (date) => {
    if (date) {
      return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
    }
    return "";
  };

  const formattedData = data.map((item) => ({
    ...item,
    disasterDate: formatDate(item.disasterDate),
  }));

  const datepickerparams = {
    params: {
      value: new Date(), // Set default value to today
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };

  const toolbarStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const handleNavigate = (route) => {
    navigate(`${route}`);
  };

  const ChartCard = ({ title, children }) => (
    <div className="min-w-[300px] flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  const ChartCardLong = ({ title, children }) => (
    <div className="w-fit flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  // if (loading) {
  //   return <div className=""></div>;
  // }

  return (
    <div
      className={`mq825:flex-wrap box-border flex w-[1640px] max-w-full flex-col items-start justify-start gap-[20px] px-2.5 py-[15px] text-left  text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
    >
      <div
        className={`mq825:flex-wrap box-border flex w-[1640px] max-w-full flex-col items-start justify-start gap-[20px] px-2.5 py-[15px] text-left  text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
      >
        <div className="flex flex-row items-center justify-start py-0 pl-0 pr-5">
          <div className="flex flex-row items-start justify-start">
            <a className="relative inline-block min-w-[35px] font-medium leading-[16px] text-[inherit] [text-decoration:none]">
              Home
            </a>
          </div>
        </div>
        {/* Home Resource Start */}

        <section className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left  text-xs text-darkslateblue">
          <div className="sticky top-[0] z-[5] box-border flex max-w-full flex-row items-center justify-between gap-[-63px] self-stretch px-0 py-0">
            <div className="box-border flex w-[1627px] max-w-[103%] shrink-0 flex-col items-start justify-start py-0 pl-0 pr-5">
              <a className="title-text mb-3 text-left  font-bold text-[#193861]">
                RESOURCES
              </a>
            </div>
            <div className="z-[1] flex flex-row items-center justify-end"></div>
          </div>
          <div className="box-border flex max-w-full flex-row flex-wrap items-start justify-center gap-[27px] self-stretch rounded-3xs px-0 py-0 font-inter text-gray-500">
            <div
              onClick={() => handleNavigate("/library/certificationvideos")}
              className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-between gap-[-2px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[30px] py-[42px] text-left  text-base text-darkslateblue [row-gap:20px] hover:cursor-pointer  mq450:flex-wrap`}
            >
              <div className="flex w-[251.8px] flex-col items-start justify-center gap-[20px]">
                <b className="relative inline-block min-w-[91px] leading-[20px] ">
                  Visit Library
                </b>
                <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                  Learn more about specific types of disasters, response, and
                  recovery.
                </div>
              </div>
              <LibraryIcon className="relative h-[65px] w-[65px] shrink-0 overflow-hidden mq450:ml-0" />
            </div>
            <div
              onClick={() => handleNavigate("/map")}
              className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
            >
              <div className="flex min-w-[155px] flex-1 flex-col items-start justify-center gap-[20px]">
                <a className="relative inline-block min-w-[70px] font-bold leading-[20px] text-[inherit] [text-decoration:none]">
                  Visit Map
                </a>
                <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                  See active long term recovery groups and local resources.
                </div>
              </div>
              <MapsIcon className="relative h-[70px] w-[70px] shrink-0 overflow-hidden" />
            </div>

            <div
              onClick={() => handleNavigate("/opportunities/jobopportunities")}
              className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
            >
              <div className="flex min-w-[152px] flex-1 flex-col items-start justify-center gap-[20px]">
                <a className="relative font-bold leading-[20px] text-[inherit] [text-decoration:none]">
                  Visit Job Opportunities
                </a>
                <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                  Explore job opportunities available in a disaster area.
                </div>
              </div>
              <JobOpportunitiesIcon className="relative h-[75px] w-[75px] shrink-0 overflow-hidden" />
            </div>

            <div
              onClick={() =>
                handleNavigate("/opportunities/volunteeropportunities")
              }
              className={`box-border flex min-w-[317px] max-w-full flex-1 flex-row items-center justify-start gap-[8px] rounded-md border-[1px] border-solid border-gray-200 bg-white px-[29px] py-[42px] text-left  text-base text-darkslateblue hover:cursor-pointer mq450:flex-wrap ${className}`}
            >
              <div className="flex min-w-[155px] flex-1 flex-col items-start justify-center gap-[20px]">
                <b className="relative leading-[20px]">
                  Visit Volunteer Opportunities
                </b>
                <div className="text-darkslategray relative self-stretch text-sm font-semibold">
                  Explore volunteer opportunities available in a disaster area.
                </div>
              </div>
              <VolunteerOpportunitiesIcon className="relative h-[70px] w-[70px] shrink-0 overflow-hidden" />
            </div>
          </div>
        </section>
        {/* Home Resource End */}

        {/* Active Disaster Grid Start */}
        <section className="border-soft-gray box-border w-full rounded-3xs border-[1px] border-solid bg-white px-[19px] pb-5 pt-[18px] text-left  text-xs text-darkslateblue">
          <div>
            <h1 className="title-text mb-3 text-left text-[#193861]">
              ACTIVE DISASTERS
            </h1>

            <GridComponent
              dataSource={data}
              allowSorting={true}
              sortSettings={sortSettings}
              // editSettings={editing} // Use the editing state
              actionComplete={actionComplete}
              // toolbar={
              //   editing.allowEditing &&
              //   editing.allowAdding &&
              //   editing.allowDeleting
              //     ? toolbarOptions
              //     : []
              // } // Remove toolbar for citizen and partner
              toolbarClick={toolbarClick}
              toolbarStyle={toolbarStyle}
              filterSettings={filterSettings}
              allowFiltering={true}
              queryCellInfo={queryCellInfo}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disaster_id"
                  headerText="Disaster ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_name"
                  headerText="Disaster"
                  width="100"
                  textAlign="Left"
                  editType="stringedit" // Ensure editType is set
                />
                <ColumnDirective
                  field="disaster_status"
                  headerText="Status"
                  width="100"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Active", value: "Active" },
                        { text: "Inactive", value: "Inactive" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="disaster_date"
                  headerText="Date"
                  width="100"
                  format="MM/dd/yyyy"
                  editType="datepickeredit"
                  edit={datepickerparams}
                />

                <ColumnDirective
                  field="location"
                  headerText="Location"
                  width="100"
                  editType="stringedit" // Ensure editType is set
                />
                {/* {userRole === "center" && ( // Conditionally render the Actions column
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                )} */}
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Edit,
                  CommandColumn,
                  Toolbar,
                  Filter,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        </section>
        {/* Active Disaster Grid End */}
      </div>
    </div>
  );
};

export default CitizenHome;
