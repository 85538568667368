import React, { useEffect, useState } from "react"; // Added useEffect and useState
import PropTypes from "prop-types";
import DrcCenterService from "../service/citizen/DrcCenterService"; // Adjusted path
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../firebase";

const DrcCenter = ({ className = "" }) => {
  const [data, setData] = useState(null); // State to hold data
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();

            const userDoc = await DrcCenterService.getCitizenProfileByCenterId(
              userData.center_id
            ); // Fetch user data

            if (userDoc) {
              console.log("User doc: ", userDoc);

              setData(userDoc);
            } else {
              setData({ id: userDocSnap.id, ...userDocSnap.data() });
            }
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setError("User is not logged in.");
      }
      setLoading(false);
    };
    fetchData();
  }, []); // Fetch data on component mount

  const renderField = (label, value) => {
    return (
      <div className="flex flex-col items-start justify-start gap-1">
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`box-border flex h-10 flex-row items-center justify-between self-stretch rounded-md border-[1px] border-solid border-gray-300 bg-gray-200 px-3 py-2`}
        >
          <div
            className={`flex-1  text-xs ${
              value ? "font-semibold text-gray-800" : "text-gray-500"
            } text-left`}
          >
            {value || "N/A"}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-lg text-gray-700 ">Loading...</div>
      </div>
    ); // Show loading state
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex items-center gap-2  text-xs text-darkslateblue">
          <a className="font-medium">Local Community</a>
          <span className="text-base font-semibold text-gray-400">/</span>
          <span className="font-medium">Center Information</span>
        </div>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <b className="text-md relative  leading-[21px]">
              CENTER INFORMATION
            </b>
            <p className="relative mt-2  text-sm font-medium leading-[21px]">
              If you find yourself in a location without a certified Community,
              I encourage you to contact your local emergency management
              authorities to learn more about the DRC recovery awareness program
              and take steps towards becoming a certified community.
            </p>
          </div>
        </header>
        <div className="mr-3 flex flex-col gap-4 self-stretch">
          {renderField("Name of Hub", data?.name_of_hub || "")}
          {renderField(
            "Contact Person",
            `${data?.first_name || ""} ${data?.last_name || ""}`
          )}
          {renderField(
            "Address",
            `${data?.address1 || ""} ${data?.address2 || ""} ${
              data?.city || ""
            } ${data?.state || ""} ${data?.zip || ""}`.trim() || ""
          )}
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {renderField("Email", data?.email || "")}
            </div>
            <div className="flex-1">
              {renderField("Website", data?.website || "")}
            </div>
          </div>
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {renderField("Social Media", data?.soc_med || "")}
            </div>
            <div className="flex-1">
              {renderField(
                "Other Information Provided",
                data?.other_info || ""
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

DrcCenter.propTypes = {
  className: PropTypes.string,
};

export default DrcCenter;
