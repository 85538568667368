import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import Profile from "./Profile";
import Password from "./Password";
import Pin from "./Pin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const Menu = ({ isOpen, onClose, initialData }) => {
  const [userData, setUserData] = useState(initialData || {});
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const menuRef = useRef(null);
  const { logout, role, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              console.log("Center");
              const centerDocSnap = await getDoc(centerDocRef);
              if (centerDocSnap.exists()) {
                setUserData(centerDocSnap.data());

                console.log("Center data:", centerDocSnap.data());
              }
            } else if (role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDocSnap = await getDoc(partnerDocRef);
              if (partnerDocSnap.exists()) {
                setUserData(partnerDocSnap.data());

                console.log("Partner data:", partnerDocSnap.data());
              }
            } else {
              setUserData(userData);
              setUserRole(userData.user_role);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen || showProfile || showPassword || showPin) {
      const fetchUserData = async () => {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;

        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              if (userData.user_role === "center") {
                const centerDocRef = doc(db, "centers", userData.center_id);
                const centerDocSnap = await getDoc(centerDocRef);
                if (centerDocSnap.exists()) {
                  setUserData(centerDocSnap.data());
                }
              } else if (userData.user_role === "partner") {
                const partnerDocRef = doc(db, "partners", userData.partner_id);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  setUserData(partnerDocSnap.data());
                }
              } else {
                setUserData(userData);
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      };

      fetchUserData();
    }
  }, [isOpen, showProfile, showPassword, showPin]);

  if (!isOpen && !showProfile && !showPassword && !showPin) return null;

  const handleProfileUpdate = (updatedData) => {
    setUserData(updatedData);
  };

  const handleProfileClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowProfile(true), 100); // Open the Profile popup after a short delay
  };

  const handlePasswordClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPassword(true), 100); // Open the Password popup after a short delay
  };

  const handlePinClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPin(true), 100); // Open the Pin popup after a short delay
  };

  const logoutClicked = () => {
    logout();
    navigate("/login");
    onClose(); // Close the menu after logging out
  };

  const switchCenterCitizenPov = () => {
    if (role == "center") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("center");
    }
    navigate("/home");
    onClose();
  };

  const switchPartnerCitizenPov = () => {
    if (role == "partner") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("partner");
    }
    navigate("/home");
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-end overflow-auto bg-black bg-opacity-50 pt-12">
          <div
            ref={menuRef}
            className="mr-2 w-[400px] overflow-hidden rounded-lg bg-white shadow-xl"
          >
            <div>
              <h2 className="bg-blue-500 px-4 pb-6 pt-6 text-base font-semibold text-white">
                My Profile
              </h2>
            </div>
            <div className="flex items-center justify-center border-b p-4">
              {userData.profile_image ? (
                <img
                  src={userData.profile_image}
                  alt="Profile"
                  className="mr-4 h-16 w-16 rounded-full"
                />
              ) : (
                <div className="text-2xl mr-4 flex h-16 w-16 items-center justify-center rounded-full bg-blue-500 font-semibold text-white">
                  {userData.first_name
                    ? userData.first_name.charAt(0).toUpperCase()
                    : "?"}
                </div>
              )}
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg pb-2 font-semibold text-black">
                  {userData.first_name
                    ? `${userData.first_name} ${userData.last_name || ""}`
                    : "User Name"}
                </h3>
                <p className="text-sm text-gray-800">
                  {userData.email || "user@example.com"}
                </p>
              </div>
            </div>
            <nav className="p-4">
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={handleProfileClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Profile
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                <li>
                  <button
                    onClick={handlePasswordClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                </li>
                {role === "center" && (
                  <div>
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                    <li>
                      <button
                        onClick={switchCenterCitizenPov}
                        className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                      >
                        Switch to {role == "center" ? "Citizen" : "Center"} POV
                      </button>
                    </li>
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                  </div>
                )}
                {role === "partner" && (
                  <div>
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                    <li>
                      <button
                        onClick={switchPartnerCitizenPov}
                        className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                      >
                        Switch to {role == "partner" ? "Citizen" : "Partner"}{" "}
                        POV
                      </button>
                    </li>
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                  </div>
                )}
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                {(role === "citizen" || role === "partner") && (
                  <div>
                    <li>
                      <button
                        onClick={handlePinClick}
                        className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                      >
                        Change Pin
                      </button>
                    </li>
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                  </div>
                )}
              </ul>
              <div className="flex items-center justify-center pt-4">
                <button
                  onClick={logoutClicked}
                  className="block cursor-pointer rounded-2xl bg-blue-500 px-4 py-2 text-[14px] font-semibold text-white hover:bg-blue-700"
                >
                  Sign Out
                </button>
              </div>
            </nav>
          </div>
        </div>
      )}
      {showProfile && userData && (
        <Profile
          isOpen={showProfile}
          onClose={() => setShowProfile(false)}
          data={userData}
          onUpdate={handleProfileUpdate}
        />
      )}
      {showPassword && (
        <Password
          isOpen={showPassword}
          onClose={() => setShowPassword(false)}
        />
      )}
      {(role === "citizen" || role === "partner") && showPin && (
        <Pin isOpen={showPin} onClose={() => setShowPin(false)} />
      )}
    </>
  );
};

export default Menu;
