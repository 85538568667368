import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  updateDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import mailer from "../service/api/mailer";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
const Register = () => {
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [code, setCode] = useState("");
  const [agree, setAgree] = useState(false);
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await getUserByEmail(auth.currentUser.email);
        if (user) {
          setUserType(user.userType);
          setFirstName(user.first_name);
          setLastName(user.last_name);
        } else {
          console.error("User not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  console.log(auth);
  const onContinue = async () => {
    try {
      const user = await getUserByEmail(auth.currentUser.email);

      if (user.otp == code) {
        const userRef = doc(db, "users", user.id);

        await updateDoc(userRef, {
          status: "active",
        });

        const isNewlyRegistered =
          localStorage.getItem("newlyRegistered") === "true";

        if (isNewlyRegistered) {
          switch (user.user_role) {
            case "center":
              navigate("/centerprofile");
              break;
            case "partner":
              navigate("/partnerprofile");
              break;
            case "citizen":
              navigate("/citizenprofile");
              break;
            default:
              navigate("/home");
          }
        } else {
          navigate("/home");
        }
      } else {
        alert("Not match!");
        setIsInvalidCode(true);
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const resendCode = async () => {
    try {
      const user = await getUserByEmail(auth.currentUser.email);
      const otp = generateOtp();
      const userRef = doc(db, "users", user.id);

      await updateDoc(userRef, {
        otp: otp.toString(),
      });

      await mailer.sendOtpEmail(auth.currentUser.email, otp, user.first_name);

      alert("Email sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto   bg-[rgb(225,190,116)]/[0.13] px-8">
      <div className="!item-center login-form relative flex h-[90vh] w-[70vw] !justify-center   rounded-xl bg-white shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
        <div className="flex w-2/3 flex-col items-center justify-center px-11  md:px-0">
          <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
            <img
              loading="lazy"
              src={blueSkyLogo}
              className="aspect-[0.99] w-[90px] object-contain"
              alt="DARCI logo"
            />
            <label
              className="mt-2.5"
              style={{ fontSize: "32px", fontWeight: 700 }}
            >
              DARCI
            </label>
          </header>
          <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center ">
            <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
              <div className="text-xl leading-none">
                <span className="text-base">Powered by </span>
                <span className="text-base text-blue-500">BlueSky</span>
              </div>
              <div className="mt-1.5 text-xs">Step 3 of 3</div>
              <div className="ml-1 mt-1.5 text-base leading-none">
                Create account as a
                <span className="ml-1 text-blue-500">{userType}</span>
              </div>
            </div>
          </div>
          <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
            <h2 className="text-xl max-md:max-w-full font-semibold leading-tight text-slate-600">
              Enter Your Confirmation Code
            </h2>
            {userType === "Citizen" && (
              <div className="mt-4 flex">
                <div>
                  <img
                    loading="lazy"
                    src={userIcon}
                    className="aspect-[0.99] w-[40px] object-contain"
                  />
                </div>
                <div className="ml-3">
                  <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                    {auth?.currentUser?.email}
                  </p>
                  <p
                    className="max-md:max-w-full mt-2 whitespace-nowrap"
                    style={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    {lastName ? lastName : "Last Name not provided"},
                    {firstName ? firstName : "First Name not provided"}
                  </p>
                </div>
              </div>
            )}
            <section className="max-md:max-w-full mt-2.5 mt-4 flex w-full flex-col justify-center">
              <p className="font-small max-md:max-w-full text-slate-600">
                Please enter the confirmation code sent to your email.
              </p>
              <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                <input
                  type="text"
                  aria-label="Confirmation code"
                  className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] focus:outline-none"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                {isInvalidCode ? (
                  <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                    You have entered an invalid code!
                  </div>
                ) : (
                  <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                    {" "}
                  </div>
                )}
                <button
                  className="my-auto w-[121px] self-stretch bg-white text-right text-blue-600"
                  onClick={resendCode}
                >
                  Resend Code
                </button>
              </div>
              <div
                className="flex max-w-[483px] flex-col justify-center tracking-wide"
                style={{ marginTop: "20px" }}
              >
                <div className="max-md:max-w-full mt-3 flex w-full flex-col justify-center text-center">
                  <button
                    className="max-md:max-w-full w-full gap-2.5 self-stretch whitespace-nowrap rounded-md bg-blue-500 p-2.5 text-xs font-bold text-white"
                    onClick={onContinue}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="block  h-full w-1/3 rounded-r-xl bg-blue-500 px-5 py-5 md:hidden ">
          <div
            className="text-xl login-desc text-white"
            style={{ fontSize: "20px", fontWeight: 400 }}
          >
            Welcome to the DARCI Program, powered by Blue Sky.
            <br />
            <br />
            We are here to support your efforts in strengthening community
            preparedness and recovery.
            <br />
            <br />
            Access comprehensive tools, resources, and insights to enhance your
            leadership, response, and recovery capabilities.
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  className: PropTypes.string,
};

export default Register;
