import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
  DetailRow,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import DisasterMgtService from "../../service/citizen/DisasterMgtService";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import assistanceLogService from "../../service/citizen/assistanceLogService";
import disasterCategories from "../../disaster_categories.json";

const sortSettings = {
  allowSorting: true,
};

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

export const ImageDialog = ({ isOpen, onClose, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!isOpen || !Array.isArray(images) || images.length === 0) return null;

  // Filter out any invalid image objects
  const validImages = images.filter(
    (img) => img && typeof img === "object" && "src" in img
  );

  if (validImages.length === 0) return null;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : validImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < validImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const currentImage = validImages[currentIndex];

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
        <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-black bg-opacity-50 p-4 text-white">
          <h2 className="text-xl truncate font-bold">
            {currentImage.originalName ||
              currentImage.name ||
              `Image ${currentIndex + 1}`}
          </h2>
          <button
            onClick={onClose}
            className="text-white text-3xl bg-black bg-opacity-50 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-75 transition-all cursor-pointer"
          >
            &times;
          </button>
        </div>
        <div className="flex-grow relative mt-16 bg-black">
          <img
            src={currentImage.src}
            alt={currentImage.originalName || currentImage.name || `Image ${currentIndex + 1}`}
            className="absolute inset-0 w-full h-full object-contain"
          />
          {validImages.length > 1 && (
            <>
              <button
                onClick={handlePrev}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white w-12 h-12 flex items-center justify-center rounded-full hover:bg-opacity-75 transition-all cursor-pointer"
              >
                &lt;
              </button>
              <button
                onClick={handleNext}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white w-12 h-12 flex items-center justify-center rounded-full hover:bg-opacity-75 transition-all cursor-pointer"
              >
                &gt;
              </button>
            </>
          )}
        </div>
        <div className="rounded-b-lg bg-black bg-opacity-50 p-4 text-white">
          <p className="text-center text-sm">
            Image {currentIndex + 1} of {validImages.length}
          </p>
        </div>
      </div>
       
    </div>
  );
};

function compressImage(file, maxSizeMB) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = img.width;
        const maxHeight = img.height;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.floor((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.floor((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        let quality = 0.9; // Start with high quality
        let dataUrl = canvas.toDataURL("image/jpeg", quality);

        while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
          quality -= 0.1;
          dataUrl = canvas.toDataURL("image/jpeg", quality);
        }

        resolve(dataUrl);
      };

      img.onerror = function (error) {
        reject(error);
      };
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

// Upload image to Firebase Storage
async function uploadImageToStorage(filePath, dataUrl) {
  const storage = getStorage();
  const storageRef = ref(storage, filePath);

  await uploadString(storageRef, dataUrl, "data_url");

  const downloadURL = await getDownloadURL(storageRef);

  return downloadURL;
}

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "Menu",
  mode: "Immediate",
  operators: {
    stringOperator: [
      { value: "contains", text: "Contains" },
      { value: "startswith", text: "Starts With" },
      { value: "endswith", text: "Ends With" },
    ],
    numberOperator: [
      { value: "equal", text: "Equal" },
      { value: "greaterthan", text: "Greater Than" },
      { value: "lessthan", text: "Less Than" },
    ],
    dateOperator: [
      { value: "equal", text: "Equal" },
      { value: "greaterthan", text: "After" },
      { value: "lessthan", text: "Before" },
    ],
  },
};

function AssistanceLog( { selectedDisasters, activeDisasters }) {
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState({
    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
  });
  const [RecoveryAssistanceData, setRecoveryAssistanceData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isDialogOpen && dialogRef.current) {
      dialogRef.current.show();
    } else if (!isDialogOpen && dialogRef.current) {
      dialogRef.current.hide();
    }
  }, [isDialogOpen]);

  const handleImageClick = (images) => {
    setSelectedImage(images);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  let categoryElement, categoryObj, subcategoryElement, subcategoryObj;

  const categoryParams = {
    create: () => {
      categoryElement = document.createElement("input");
      return categoryElement;
    },
    destroy: () => {
      categoryObj && categoryObj.destroy();
    },
    read: () => {
      return categoryObj ? categoryObj.text : null;
    },
    write: (args) => {
      const header = document.createElement("label");
      header.textContent = "Category";
      header.style.display = "block";
      header.style.marginBottom = "4px";
      header.style.fontSize = "12px";
      header.style.color = "#666";

      categoryElement.parentNode.insertBefore(header, categoryElement);

      categoryObj = new DropDownList({
        dataSource: disasterCategories.categories,
        fields: { text: "categoryName", value: "categoryId" },
        value: args.rowData.categoryId,
        text: args.rowData.category,
        change: (e) => {
          const selectedCategory = e.value;
          setSelectedCategory(selectedCategory);
          if (subcategoryObj) {
            subcategoryObj.enabled = true;
            const tempQuery = new Query().where(
              "categoryId",
              "equal",
              selectedCategory
            );
            subcategoryObj.query = tempQuery;
            subcategoryObj.text = "";
            subcategoryObj.dataBind();
          }
        },
        placeholder: "Select a category",
        floatLabelType: "Never",
      });
      categoryObj.appendTo(categoryElement);
    },
  };

  const subcategoryParams = {
    create: () => {
      subcategoryElement = document.createElement("input");
      return subcategoryElement;
    },
    destroy: () => {
      subcategoryObj && subcategoryObj.destroy();
    },
    read: () => {
      return subcategoryObj ? subcategoryObj.text : null;
    },
    write: (args) => {
      const header = document.createElement("label");
      header.textContent = "Sub Category";
      header.style.display = "block";
      header.style.marginBottom = "4px";
      header.style.fontSize = "12px";
      header.style.color = "#666";

      subcategoryElement.parentNode.insertBefore(header, subcategoryElement);

      subcategoryObj = new DropDownList({
        dataSource: new DataManager(disasterCategories.subcategories),
        fields: { text: "subcategoryName", value: "subcategoryName" },
        value: args.rowData.subcategory,
        text: args.rowData.subcategory,
        enabled: args.rowData.categoryId !== undefined,
        query: new Query().where(
          "categoryId",
          "equal",
          args.rowData.categoryId || selectedCategory
        ),
        placeholder: "Select a subcategory",
        floatLabelType: "Never",
      });
      subcategoryObj.appendTo(subcategoryElement);
    },
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        fetchUserRole(currentUser.uid);
      } else {
        setData(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role;
        setUserRole(role);
        
        // Set editing permissions based on role
        if (role === "citizen" || role === "partner") {
          setEditing(prevEditing => ({
            ...prevEditing,
            allowEditing: true,  // Allow editing for citizens and partners
            allowAdding: true,   // Always allow adding
            allowDeleting: false // Disallow deleting
          }));
        } else if (role === "center") {
          setEditing(prevEditing => ({
            ...prevEditing,
            allowEditing: true,  // Allow editing for center role
            allowAdding: true,   // Always allow adding
            allowDeleting: true  // Allow deleting for center role
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId, selectedDisasters, activeDisasters]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const general = await DisasterMgtService.fetchRecords(
          userId,
          "general"
        );

        const filteredGeneral = general.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
  
        setGeneralData(filteredGeneral.filter((item) => !item.is_deleted));

        const home = await DisasterMgtService.fetchRecords(
          userId,
          "home_repair"
        );

        const filteredHome = home.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });

        setHomeRepairData(filteredHome.filter((item) => !item.is_deleted));

        const landscaping = await DisasterMgtService.fetchRecords(
          userId,
          "landscaping"
        );

        const filteredLandscaping = landscaping.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });

        setLandscapingData(filteredLandscaping.filter((item) => !item.is_deleted));

        const vehicle = await DisasterMgtService.fetchRecords(
          userId,
          "vehicle"
        );

        const filteredVehicle = vehicle.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });

        setVehicleData(filteredVehicle.filter((item) => !item.is_deleted));

        const contents = await DisasterMgtService.fetchRecords(
          userId,
          "contents"
        );

        const filteredContents = contents.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });

        setContentsData(filteredContents.filter((item) => !item.is_deleted));
        
       
        
        
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  let elem;
  let uploadObj;
  let strm;

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  //Immediate Response
  const [ImmediateResponseData, setImmediateResponseData] = useState([]);
  const [isImmediateResponseVisible, setIsImmediateResponseVisible] =
    useState(true);
  const [lastImmediateResponseEditedTime, setLastImmediateResponseEditedTime] =
    useState(null);

  const toggleImmediateResponse = (event) => {
    event.preventDefault();
    setIsImmediateResponseVisible(!isImmediateResponseVisible);
  };

  const ImmediateResponseGridRef = useRef(null);

  const handleAddClickImmediateResponse = () => {
    if (ImmediateResponseGridRef.current) {
      ImmediateResponseGridRef.current.addRecord();
    }
  };

  const queryCellInfoImmediateResponse = (args) => {
    if (args.column.field === "disasterName") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
               background-color: ${bgColor};
               color: ${textColor};
               padding: 5px 10px;
               border-radius: 15px;
               display: inline-block;
               font-weight: bold;
               text-align: center;
             ">${args.data.disasterName}</div>`;
      }
    }
  };
  const [selectedRow, setSelectedRow] = useState(null);
  // State for RecoveryAssistance section
  const [isRecoveryAssistanceVisible, setIsRecoveryAssistanceVisible] =
    useState(true);
  const [lastRecoveryAssistanceEditedTime, setLasRecoveryAssistanceEditedTime] =
    useState(null);
  const [maxRecoveryAssistanceID, setMaxRecoveryAssistanceID] = useState(
    RecoveryAssistanceData.length > 0
      ? Math.max(
          ...RecoveryAssistanceData.map((item) => item.RecoveryAssistanceID)
        )
      : 0
  );

  const toggleRecoveryAssistance = (event) => {
    event.preventDefault();
    setIsRecoveryAssistanceVisible(!isRecoveryAssistanceVisible);
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const actionBeginImmediate = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.ImmediateResponseID = generateUniqueId();
        args.data.is_deleted = false; // Set is_deleted to false for new records
        args.data.disasterDate = formatDate(
          args.data.disasterDate || new Date()
        );

        const sanitizedData = sanitizeData(args.data);
        await assistanceLogService.addRecord(
          userId,
          sanitizedData,
          "immediateAssistance"
        );
      } else if (args.action === "edit") {
        args.data.is_deleted = false; // Ensure is_deleted is false when editing
        args.data.disasterDate = formatDate(args.data.disasterDate);
        setSelectedRow(args.data); // Use setSelectedRow instead of direct assignment

        const sanitizedData = sanitizeData(args.data);
        await assistanceLogService.updateRecord(
          userId,
          args.data.ImmediateResponseID,
          sanitizedData,
          "immediateAssistance"
        );
      }
    }
    if (args.requestType === "beginEdit") {
      setSelectedRow(args.rowData); // Use setSelectedRow instead of direct assignment
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.ImmediateResponseID;
          const fileUrl = docData.photoGeneral;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the is_deleted flag to true
          await assistanceLogService.updateRecord(
            userId,
            documentId,
            { ...docData, is_deleted: true },
            "immediateAssistance"
          );
        }
        args.cancel = true; // Prevent actual deletion from the grid
        await fetchData(); // Refresh the data after marking as deleted
      } catch (error) {
        console.error("Error marking document as deleted:", error);
      }
    }
  };

  const actionCompleteImmediate = async (args) => {
    if (args.requestType === "save") {
      await fetchData(); // Refresh the data after save
    }
    if (args.requestType === "beginEdit") {
      const datepicker = args.form.elements["disasterDate"];
      if (datepicker && datepicker.ej2_instances[0]) {
        datepicker.ej2_instances[0].value = new Date(args.rowData.disasterDate);
      }
    }
  };

  const { categories, subcategories } = disasterCategories;
  const createImageEditTemplate = (category) => ({
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };
  
      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Images will be dynamically inserted here -->
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Upload Images
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>
          
        </div>
      `;
  
      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagesPreviews = elem.querySelector("#imagesPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");
  
      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });
  
      uploadObj.appendTo(inputElement);
  
      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });
  
      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });
  
      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);
  
            const filePath = `disaster-mgt/${category}/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(filePath, compressedFile);
            
            strm = strm ? [...strm, { src: downloadURL, name: file.name }] : [{ src: downloadURL, name: file.name }];
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }
  
      function onUploadFailure(args) {
        console.log("File failed to upload");
      }
  
      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${progress}%`;
        if (progress === 100) {
          setTimeout(() => {
            uploadProgressContainer.style.display = "none";
          }, 1000);
        }
      }
  
      function displayUploadedImages(images) {
        if (!Array.isArray(images)) {
          console.error('Images is not an array:', images);
          return;
        }
        
        imagesPreviews.innerHTML = images.map((image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `).join('');
      }
  
      // Function to remove an image
      window.removeImage = (index) => {
        strm.splice(index, 1);
        displayUploadedImages(strm);
      };
  
      // Display the initial images if available
      const photoField = `photo_${category}`;
      if (args.rowData[photoField]) {
        strm = Array.isArray(args.rowData[photoField]) ? args.rowData[photoField] : [args.rowData[photoField]];
        displayUploadedImages(strm);
      } else {
        imagesPreviews.innerHTML = "";
      }
    },
  });
  
  // Use the function for both general and landscaping
  const generalEdit = createImageEditTemplate('general');
const landscapingEdit = createImageEditTemplate('landscaping');
const contentsEdit = createImageEditTemplate('contents');
const vehicleEdit = createImageEditTemplate('vehicle');
const homeRepairEdit = createImageEditTemplate('home_repair');

// Update the grid templates
const gridTemplate = (props) => {
  const images = Array.isArray(props.photo_general) ? props.photo_general : (props.photo_general ? [props.photo_general] : []);
  return renderImagePreview(images);
};

const landscapingTemplate = (props) => {
  const images = Array.isArray(props.photo_landscaping) ? props.photo_landscaping : (props.photo_landscaping ? [props.photo_landscaping] : []);
  return renderImagePreview(images);
};

const contentsTemplate = (props) => {
  const images = Array.isArray(props.photo_contents) ? props.photo_contents : (props.photo_contents ? [props.photo_contents] : []);
  return renderImagePreview(images);
};

const vehicleTemplate = (props) => {
  const images = Array.isArray(props.photo_vehicle) ? props.photo_vehicle : (props.photo_vehicle ? [props.photo_vehicle] : []);
  return renderImagePreview(images);
};

const homeRepairTemplate = (props) => {
  const images = Array.isArray(props.photo_home_repair) ? props.photo_home_repair : (props.photo_home_repair ? [props.photo_home_repair] : []);
  return renderImagePreview(images);
};

// Helper function to render image preview
const renderImagePreview = (images) => {
  return (
    <div 
      className="image-name-container" 
      onClick={() => handleImageClick(images)}
      style={{
        cursor: 'pointer',
        padding: '10px',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        textAlign: 'center',
        minWidth: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="#3B82F6"
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        style={{ marginRight: '8px' }}
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
        <circle cx="8.5" cy="8.5" r="1.5"/>
        <polyline points="21 15 16 10 5 21"/>
      </svg>
      <p className="text-sm">
        {images.length > 0 ? `${images.length} image${images.length > 1 ? 's' : ''}` : 'No images'}
      </p>
    </div>
  );
};

const [generalData, setGeneralData] = useState([]);
   const [isGeneralVisible, setIsGeneralVisible] = useState(true);
   const [lastGeneralEditedTime, setLastGeneralEditedTime] = useState(null);
 
   const toggleGeneral = (event) => {
     event.preventDefault();
     setIsGeneralVisible(!isGeneralVisible);
   };
 
   const generalGridRef = useRef(null);
 
   const handleAddClickGeneral = () => {
     if (generalGridRef.current) {
       generalGridRef.current.addRecord();
     }
   };

   const actionBeginGeneral = async (args) => {
    if (args.requestType === "save" || args.requestType === "beginEdit" || args.requestType === "add") {
      // Hide the "value" column when editing or adding
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }
  
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All") 
      ? activeDisasters
      : selectedDisasters;
      
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
          args.cancel = true;
          alert("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
  
    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.generalID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = userId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
  
        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(userId, sanitizedData, "general");
        
        setLastGeneralEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (updatedData.editor_ID !== userId || updatedData.editor_type !== userRole) {
            args.cancel = true;
            alert("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
        
        const documentId = updatedData.generalID;
        const sanitizedData = sanitizeData(updatedData);
        
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );
  
        setLastGeneralEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }
  
    if (args.requestType === "delete") {
      if (userRole !== "center") {
        args.cancel = true;
        alert("You don't have permission to delete records.");
        return;
      }
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photo_general;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
          }
  
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: serverTimestamp() },
            "general"
          );
        }
        
        setLastGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };
  
  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }
  
      const documentId = args.data.generalID;
      let updatedData = { ...args.data };
  
      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }
  
      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails ? [updatedData.assistanceDetails] : [];
      }
  
      const sanitizedData = sanitizeData(updatedData);
  
      try {
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );
  
        // Refresh the grid data
        const allGeneralData = await DisasterMgtService.getGeneral(userId);
        const filteredGeneralData = allGeneralData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
        setGeneralData(filteredGeneralData.filter(item => !item.is_deleted));

        setLastGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating general record:", error);
      }
    }
  };

  const generalDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedGeneralData = generalData.map(item => 
        item.vehicleID === props.vehicleID 
          ? { ...item, assistanceDetails: newDetails, value: totalAmount } 
          : item
      );
      setGeneralData(updatedGeneralData);
  
      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          userId,
          props.generalID,
          { assistanceDetails: newDetails, value: totalAmount },
          "general"
        );
        
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };
  
    return (
      <div>
        <h3>Assistance Details</h3>
        <AssistanceDetailsGrid 
          assistanceDetails={props.assistanceDetails || []} 
          onAssistanceDetailsChange={updateAssistanceDetails}
          generalID={props.generalID}
          userId={userId}
        />
      </div>
    );
  };

  //Home Repair
  
  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(true);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] = useState(null);
  const [homeRepairData, setHomeRepairData] = useState([]);
    
  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  const handleAddClickHomeRepair = () => {
    if (homeRepairGridRef.current) {
      homeRepairGridRef.current.addRecord();
    }
  };

  
  const actionBeginHomeRepair = async (args) => {
    if (args.requestType === "save" || args.requestType === "beginEdit" || args.requestType === "add") {
      // Hide the "value" column when editing or adding
      const cols = homeRepairGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All") 
      ? activeDisasters
      : selectedDisasters;
      
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
          args.cancel = true;
          alert("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
  
    if (args.requestType === "save") {
      let updatedData = { ...args.data };  // Create a copy of args.data
  
      if (args.action === "add") {
        updatedData.homeRepairID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = userId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
  
        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(userId, sanitizedData, "home_repair");
        
        setLastHomeRepairEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (updatedData.editor_ID !== userId || updatedData.editor_type !== userRole) {
            args.cancel = true;
            alert("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
        
        const documentId = updatedData.homeRepairID;
        const sanitizedData = sanitizeData(updatedData);
        
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "home_repair"
        );
        
        setLastHomeRepairEditedTime(new Date());
      }
  
      // Update args.data with the new values
      Object.assign(args.data, updatedData);
    }
  
    if (args.requestType === "delete") {
      if (userRole !== "center") {
        args.cancel = true;
        alert("You don't have permission to delete records.");
        return;
      }
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photo_general;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
          }
  
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: serverTimestamp() },
            "general"
          );
        }
        
        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };
  
  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save") {
      const cols = homeRepairGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }
      const documentId = args.data.homeRepairID;
      let updatedData = { ...args.data };
  
      // If we're editing an existing record
      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }
  
      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails ? [updatedData.assistanceDetails] : [];
      }
  
      // Sanitize the data before saving
      const sanitizedData = sanitizeData(updatedData);
  
      try {
        // Update the home repair record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "home_repair"
        );
  
        // Refresh the grid data
        const allHomeRepairData = await DisasterMgtService.getHomeRepair(userId);
        const filteredHomeRepairData = allHomeRepairData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
        setHomeRepairData(filteredHomeRepairData.filter(item => !item.is_deleted));
  
        // Update the last edited time
        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating home repair record:", error);
      }
    }
  };
  
  
  const detailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedHomeRepairData = homeRepairData.map(item => 
        item.homeRepairID === props.homeRepairID 
          ? { ...item, assistanceDetails: newDetails, value: totalAmount } 
          : item
      );
      setHomeRepairData(updatedHomeRepairData);
  
      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          userId,
          props.homeRepairID,
          { assistanceDetails: newDetails, value: totalAmount },
          "home_repair"
        );
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };
  
    return (
      <div>
        <h3>Assistance Details</h3>
        <AssistanceDetailsGrid 
          assistanceDetails={props.assistanceDetails || []} 
          onAssistanceDetailsChange={updateAssistanceDetails}
          homeRepairID={props.homeRepairID}
          userId={userId}
        />
      </div>
    );
  };

  // Extract unique categories from the data

  //End of Home Repair

    //Start of Landscaping
    const [landscapingData, setLandscapingData] = useState([]);
  const [isLandscapingVisible, setIsLandscapingVisible] = useState(true);
  const [lastLandscapingEditedTime, setLastLandscapingEditedTime] = useState(null);

  const toggleLandscaping = (event) => {
    event.preventDefault();
    setIsLandscapingVisible(!isLandscapingVisible);
  };

  const landscapingGridRef = useRef(null);

  const handleAddClickLandscaping = () => {
    if (landscapingGridRef.current) {
      landscapingGridRef.current.addRecord();
    }
  };

  const actionBeginLandscaping = async (args) => {
    if (args.requestType === "save" || args.requestType === "beginEdit" || args.requestType === "add") {
      // Hide the "value" column when editing or adding
      const cols = landscapingGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }
  
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All") 
      ? activeDisasters
      : selectedDisasters;
      
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
          args.cancel = true;
          alert("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.landscapingID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = userId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
  
        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(userId, sanitizedData, "landscaping");
        
        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      } else if (args.action === "edit") {

        if (userRole === "citizen" || userRole === "partner") {
          if (updatedData.editor_ID !== userId || updatedData.editor_type !== userRole) {
            args.cancel = true;
            alert("You don't have permission to edit this record.");
            return;
          }
        }

        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
        
        const documentId = updatedData.landscapingID;
        const sanitizedData = sanitizeData(updatedData);
        
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "landscaping"
        );
  
        setLastLandscapingEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }
    
    if (args.requestType === "delete") {
      if (userRole !== "center") {
        args.cancel = true;
        alert("You don't have permission to delete records.");
        return;
      }
      try {
        for (const docData of args.data) {
          const documentId = docData.landscapingID;
          const fileUrl = docData.photo_landscaping;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
          }
  
          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: serverTimestamp() },
            "landscaping"
          );
        }
        
        // Update the last edited time after deletion
        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };
  
  const actionCompleteLandscaping = async (args) => {
    if (args.requestType === "save") {
      const cols = landscapingGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }
  
      const documentId = args.data.landscapingID;
      let updatedData = { ...args.data };
  
      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }
  
      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails ? [updatedData.assistanceDetails] : [];
      }
  
      const sanitizedData = sanitizeData(updatedData);
  
      // Refresh the grid data
      try {
        // Update the landscaping record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "landscaping"
        );
  
        // Refresh the grid data
        const allLandscapingData = await DisasterMgtService.getLandscaping(userId);
        const filteredLandscapingData = allLandscapingData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
        setLandscapingData(filteredLandscapingData.filter(item => !item.is_deleted));
  
        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating landscaping record:", error);
      }
    }
  };

  const landscapingDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedLandscapingData = landscapingData.map(item => 
        item.landscapingID === props.landscapingID 
          ? { ...item, assistanceDetails: newDetails, value: totalAmount } 
          : item
      );
      setLandscapingData(updatedLandscapingData);
  
      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          userId,
          props.landscapingID,
          { assistanceDetails: newDetails, value: totalAmount },
          "landscaping"
        );
        if (landscapingGridRef.current) {
          landscapingGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };
  
    return (
      <div>
        <h3>Assistance Details</h3>
        <AssistanceDetailsGrid 
          assistanceDetails={props.assistanceDetails || []} 
          onAssistanceDetailsChange={updateAssistanceDetails}
          landscapingID={props.landscapingID}
          userId={userId}
        />
      </div>
    );
  };


  //Vehicle
  const [vehicleData, setVehicleData] = useState([]);
   const [isVehicleVisible, setIsVehicleVisible] = useState(true);
   const [lastVehicleEditedTime, setLastVehicleEditedTime] = useState(null);
 
   const toggleVehicle = (event) => {
     event.preventDefault();
     setIsVehicleVisible(!isVehicleVisible);
   };
 
   const vehicleGridRef = useRef(null);
 
   const handleAddClickVehicle = () => {
     if (vehicleGridRef.current) {
       vehicleGridRef.current.addRecord();
     }
   };

   const actionBeginVehicle = async (args) => {
    if (args.requestType === "save" || args.requestType === "beginEdit" || args.requestType === "add") {
      // Hide the "value" column when editing or adding
      const cols = vehicleGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All") 
      ? activeDisasters
      : selectedDisasters;
      
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
          args.cancel = true;
          alert("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
  
    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.vehicleID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = userId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
  
        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(userId, sanitizedData, "vehicle");
        
        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      } else if (args.action === "edit") {
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
        
        const documentId = updatedData.vehicleID;
        const sanitizedData = sanitizeData(updatedData);
        
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );
  
        setLastVehicleEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }
    
    if (args.requestType === "beginEdit") {
      args.rowData = { ...args.rowData };
    }
  
    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.vehicleID;
          const fileUrl = docData.photo_vehicle;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
          }
  
          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: serverTimestamp() },
            "vehicle"
          );
        }
        
        // Update the last edited time after deletion
        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };
  
  const actionCompleteVehicle = async (args) => {
    if (args.requestType === "save") {
      const cols = vehicleGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }
  
      const documentId = args.data.vehicleID;
      let updatedData = { ...args.data };
  
      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }
  
      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails ? [updatedData.assistanceDetails] : [];
      }
  
      const sanitizedData = sanitizeData(updatedData);
  
      // Refresh the grid data
      try {
        // Update the vehicle record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "vehicle"
        );
  
        // Refresh the grid data
        const allVehicleData = await DisasterMgtService.getVehicle(userId);
        const filteredVehicleData = allVehicleData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
        setVehicleData(filteredVehicleData.filter(item => !item.is_deleted));
  
        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating vehicle record:", error);
      }
    }
  };

  const vehicleDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedVehicleData = vehicleData.map(item => 
        item.vehicleID === props.vehicleID 
          ? { ...item, assistanceDetails: newDetails, value: totalAmount } 
          : item
      );
      setVehicleData(updatedVehicleData);
  
      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          userId,
          props.vehicleID,
          { assistanceDetails: newDetails, value: totalAmount },
          "vehicle"
        );
        if (vehicleGridRef.current) {
          vehicleGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };
  
    return (
      <div>
        <h3>Assistance Details</h3>
        <AssistanceDetailsGrid 
          assistanceDetails={props.assistanceDetails || []} 
          onAssistanceDetailsChange={updateAssistanceDetails}
          vehicleID={props.vehicleID}
          userId={userId}
        />
      </div>
    );
  };

   //Contents
   const [contentsData, setContentsData] = useState([]);
   const [isContentsVisible, setIsContentsVisible] = useState(true);
   const [lastContentsEditedTime, setLastContentsEditedTime] = useState(null);
 
   const toggleContents = (event) => {
     event.preventDefault();
     setIsContentsVisible(!isContentsVisible);
   };
 
   const contentsGridRef = useRef(null);
 
   const handleAddClickContents = () => {
     if (contentsGridRef.current) {
       contentsGridRef.current.addRecord();
     }
   };

   const actionBeginContents = async (args) => {
    if (args.requestType === "save" || args.requestType === "beginEdit" || args.requestType === "add") {
      // Hide the "value" column when editing or adding
      const cols = contentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All") 
      ? activeDisasters
      : selectedDisasters;
      
    } else if (args.requestType === "beginEdit") {
      if (userRole === "citizen" || userRole === "partner") {
        if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
          args.cancel = true;
          alert("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }
  
    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.contentsID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = userId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
  
        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(userId, sanitizedData, "contents");
        
        // Update the last edited time
        setLastContentsEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (updatedData.editor_ID !== userId || updatedData.editor_type !== userRole) {
            args.cancel = true;
            alert("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All") 
          ? activeDisasters
          : selectedDisasters;
        
        const documentId = updatedData.contentsID;
        const sanitizedData = sanitizeData(updatedData);
        
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );
  
        setLastContentsEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }
    
    if (args.requestType === "delete") {
      if (userRole !== "center") {
        args.cancel = true;
        alert("You don't have permission to delete records.");
        return;
      }
      try {
        for (const docData of args.data) {
          const documentId = docData.contentsID;
          const fileUrl = docData.photo_contents;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
          }
  
          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            userId,
            documentId,
            { is_deleted: true, last_edited: serverTimestamp() },
            "contents" 
          );
        }
        
        // Update the last edited time after deletion
        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };
  
  const actionCompleteContents = async (args) => {
    if (args.requestType === "save") {
      const cols = contentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }
  
      const documentId = args.data.contentsID;
      let updatedData = { ...args.data };
  
      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }
  
      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails ? [updatedData.assistanceDetails] : [];
      }
  
      const sanitizedData = sanitizeData(updatedData);
  
      // Refresh the grid data
      try {
        // Update the contents record with the new data
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "contents"
        );
  
        // Refresh the grid data
        const allContentsData = await DisasterMgtService.getContents(userId);
        const filteredContentsData = allContentsData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some(disaster => activeDisasters.includes(disaster));
          }
          return item.associatedDisasters.some(disaster => selectedDisasters.includes(disaster));
        });
        setContentsData(filteredContentsData.filter(item => !item.is_deleted));
  
        // Update the last edited time
        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating contents record:", error);
      }
    }
  };

  const contentsDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedContentsData = contentsData.map(item => 
        item.contentsID === props.contentsID 
          ? { ...item, assistanceDetails: newDetails, value: totalAmount } 
          : item
      );
      setContentsData(updatedContentsData);
  
      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          userId,
          props.contentsID,
          { assistanceDetails: newDetails, value: totalAmount },
          "contents"
        );
        if (contentsGridRef.current) {
          contentsGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };
  
    return (
      <div>
        <h3>Assistance Details</h3>
        <AssistanceDetailsGrid 
          assistanceDetails={props.assistanceDetails || []} 
          onAssistanceDetailsChange={updateAssistanceDetails}
          contentsID={props.contentsID}
          userId={userId}
        />
      </div>
    );
  };


  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start py-[15px]`}
    >
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p>
          The Assistance Log is designed to track the support and resources
          provided during disaster recovery. By completing this log, you help
          ensure accurate documentation of aid, enabling better coordination and
          future assistance efforts. Please fill it out thoroughly to help us
          deliver timely and effective support.
        </p>
      </div>
      <div className="w-full">
      <h3 className="mb-1 text-md text-blue-500">
                Immediate Response Assistance Received
              </h3>
      

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">General</h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastGeneralEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickGeneral}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleGeneral}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isGeneralVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isGeneralVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={generalGridRef}
                dataSource={generalData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginGeneral}
                actionComplete={actionCompleteGeneral}
                detailTemplate={generalDetailTemplate}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="generalID"
                    headerText="generalID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
          field="category"
          headerText="Category"
          width="120"
          visible={false}
          defaultValue={categories.find(cat => cat.categoryId === "1").categoryName}
        />
        <ColumnDirective
          field="sub_category"
          headerText="Category"
          width="150"
          editType="dropdownedit"
          edit={{
            params: {
              dataSource: subcategories.filter(sub => sub.categoryId === "1").map(sub => sub.subcategoryName),
              fields: { text: "sub_category", value: "sub_category" },
              query: new Query(),
            },
          }}
        />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Value"
                    width="150"
                    format="C"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  />
                   <ColumnDirective
  field="photo_general"
  headerText="Photo Attachment"
  width="120"
  template={gridTemplate}
  edit={generalEdit}
/>
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter, DetailRow]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs  bg-white px-2">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h3 className="mb-1 text-lg font-bold text-blue-500">
                Recovery Assistance Received
              </h3>
            </div>
          </header>
        </section>

        <section className="mt-1 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Home Repair and Replacement
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickHomeRepair}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleHomeRepair}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isHomeRepairVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isHomeRepairVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={homeRepairGridRef}
                dataSource={homeRepairData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginHomeRepair}
                actionComplete={actionCompleteHomeRepair}
                detailTemplate={detailTemplate}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="homeRepairID"
                    headerText="homeRepairID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
          field="category"
          headerText="Category"
          width="120"
          visible={false}
          defaultValue={categories.find(cat => cat.categoryId === "2").categoryName}
        />
        <ColumnDirective
          field="sub_category"
          headerText="Category"
          width="150"
          editType="dropdownedit"
          edit={{
            params: {
              dataSource: subcategories.filter(sub => sub.categoryId === "2").map(sub => sub.subcategoryName),
              fields: { text: "subcategoryName", value: "subcategoryName" },
              query: new Query(),
            },
          }}
        />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Value"
                    width="150"
                    format="C"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  />
                  <ColumnDirective
                    field="photo_home_repair"
                    headerText="Photo Attachment"
                    width="120"
                    template={homeRepairTemplate}
                    edit={homeRepairEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter, DetailRow]}
                />
              </GridComponent>
            </div>
          )}
        </section>


        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Landscaping
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickLandscaping}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleLandscaping}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isLandscapingVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isLandscapingVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={landscapingGridRef}
                dataSource={landscapingData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginLandscaping}
                actionComplete={actionCompleteLandscaping}
                detailTemplate={landscapingDetailTemplate}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="landscapingID"
                    headerText="landscapingID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={categories.find(cat => cat.categoryId === "3").categoryName}
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories.filter(sub => sub.categoryId === "3").map(sub => sub.subcategoryName),
                        fields: { text: "subcategoryName", value: "subcategoryName" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Value"
                    width="150"
                    format="C"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  />
                  <ColumnDirective
                    field="photo_landscaping"
                    headerText="Photo Attachment"
                    width="120"
                    template={landscapingTemplate}
                    edit={landscapingEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter, DetailRow]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Vehicle(s)
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                {/* Last Edited: {formatLastEditedTime(lastVehicleEditedTime)} */}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickVehicle}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleVehicle}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isVehicleVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isVehicleVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={vehicleGridRef}
                dataSource={vehicleData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginVehicle}
                actionComplete={actionCompleteVehicle}
                detailTemplate={vehicleDetailTemplate}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="vehicleID"
                    headerText="vehicleID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={categories.find(cat => cat.categoryId === "4").categoryName}
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories.filter(sub => sub.categoryId === "4").map(sub => sub.subcategoryName),
                        fields: { text: "subcategoryName", value: "subcategoryName" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Value"
                    width="150"
                    format="C"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  />
                  <ColumnDirective
                    field="photo_vehicle"
                    headerText="Photo Attachment"
                    width="120"
                    template={vehicleTemplate}
                    edit={vehicleEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter, DetailRow]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Contents
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                {/* Last Edited: {formatLastEditedTime(lastContentsEditedTime)} */}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickContents}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleContents}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isContentsVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isContentsVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={contentsGridRef}
                dataSource={contentsData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginContents}
                actionComplete={actionCompleteContents}
                detailTemplate={contentsDetailTemplate}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contentsID"
                    headerText="contentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="120"
                    visible={false}
                    defaultValue={categories.find(cat => cat.categoryId === "5").categoryName}
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories.filter(sub => sub.categoryId === "5").map(sub => sub.subcategoryName),
                        fields: { text: "subcategoryName", value: "subcategoryName" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="120"
                  />
                  <ColumnDirective
                    field="value"
                    headerText="Value"
                    width="150"
                    format="C"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}
                  />
                  <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  />
                  <ColumnDirective
                    field="photo_contents"
                    headerText="Photo Attachment"
                    width="120"
                    template={contentsTemplate}
                    edit={contentsEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter, DetailRow]}
                />
              </GridComponent>
            </div>
          )}
        </section>
        
        
      </div>
      <ImageDialog
      isOpen={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      images={selectedImage}
    />
    </div>
  );
}
const AssistanceDetailsGrid = ({ assistanceDetails, onAssistanceDetailsChange, generalID, homeRepairID,  landscapingID, vehicleID, contentsID,  userId }) => {
  const gridRef = useRef(null);

  const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];

  const actionComplete = async (args) => {
    if (args.requestType === 'save' || args.requestType === 'delete') {
      const updatedDetails = gridRef.current.getCurrentViewRecords()
        .map(record => {
          let formattedDate = null;
          if (record.date_assistance) {
            let date;
            if (record.date_assistance instanceof Date) {
              date = record.date_assistance;
            } else if (typeof record.date_assistance === 'string') {
              date = new Date(record.date_assistance);
            }
  
            if (date && !isNaN(date.getTime())) {
              formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
            }
          }
  
          return {
            description: record.description || '',
            source: record.source || '',
            organization: record.organization || '',
            amount: typeof record.amount === 'number' ? record.amount : 0,
            date_assistance: formattedDate || '',
            photo_details: Array.isArray(record.photo_details) ? record.photo_details : []
          };
        })
        .filter(detail => Object.keys(detail).length > 0);
  
      const totalAmount = updatedDetails.reduce((sum, detail) => sum + (detail.amount || 0), 0);
  
      try {
        // Call the parent's onAssistanceDetailsChange function to update backend
        await onAssistanceDetailsChange(updatedDetails, totalAmount);
  
        // Update the grid's data source
        gridRef.current.dataSource = updatedDetails;
      } catch (error) {
        console.error("Error updating assistance details:", error);
      }
    }
  };

  let elem;
  let uploadObj;
  let strm;

  const createImageEditTemplate = (category) => ({
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl: "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };
  
      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Images will be dynamically inserted here -->
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Upload Images
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>
          
        </div>
      `;
  
      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagesPreviews = elem.querySelector("#imagesPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");
  
      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });
  
      uploadObj.appendTo(inputElement);
  
      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });
  
      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });
  
      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/assistance_details/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(filePath, compressedFile);
            
            strm = strm ? [...strm, { src: downloadURL, name: file.name }] : [{ src: downloadURL, name: file.name }];
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }
  
      function onUploadFailure(args) {
        console.log("File failed to upload");
      }
  
      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${progress}%`;
        if (progress === 100) {
          setTimeout(() => {
            uploadProgressContainer.style.display = "none";
          }, 1000);
        }
      }
  
      function displayUploadedImages(images) {
        if (!Array.isArray(images)) {
          console.error('Images is not an array:', images);
          return;
        }
        
        imagesPreviews.innerHTML = images.map((image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `).join('');
      }
  
      // Function to remove an image
      window.removeImage = (index) => {
        strm.splice(index, 1);
        displayUploadedImages(strm);
      };
  
      // Display the initial images if available
      const photoField = `photo_${category}`;
      if (args.rowData.photo_details) {
        strm = Array.isArray(args.rowData.photo_details) ? args.rowData.photo_details : [args.rowData.photo_details];
        displayUploadedImages(strm);
      } else {
        imagesPreviews.innerHTML = "";
      }
    },
  });
  
  const imageEditDetails = createImageEditTemplate();

  const template = (props) => {
    const images = Array.isArray(props.photo_details) ? props.photo_details : (props.photo_details ? [props.photo_details] : []);
    return renderImagePreview(images);
  };

  const renderImagePreview = (images) => {
    return (
      <div 
        className="image-name-container" 
        onClick={() => handleImageClick(images)}
        style={{
          cursor: 'pointer',
          padding: '5px',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          textAlign: 'center',
          minWidth: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="#3B82F6"
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          style={{ marginRight: '8px' }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
          <circle cx="8.5" cy="8.5" r="1.5"/>
          <polyline points="21 15 16 10 5 21"/>
        </svg>
        <p className="text-sm">
          {images.length > 0 ? `${images.length} image${images.length > 1 ? 's' : ''}` : 'No images'}
        </p>
      </div>
    );
  };

  const datepickerparams = {
    params: {
      format: 'MM/dd/yyyy',
      value: new Date(),
      showClearButton: false,
      showTodayButton: false,
      start: 'Decade',
      depth: 'Day'
    }
  };
  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isDialogOpen && dialogRef.current) {
      dialogRef.current.show();
    } else if (!isDialogOpen && dialogRef.current) {
      dialogRef.current.hide();
    }
  }, [isDialogOpen]);

  const handleImageClick = (images) => {
    setSelectedImage(images);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
    <GridComponent
    ref={gridRef}
    dataSource={assistanceDetails}
    editSettings={editOptions}
    toolbar={toolbarOptions}
    actionComplete={actionComplete}
  >
    <ColumnsDirective>
      <ColumnDirective field='description' headerText='Type of Assistance' width='100'/>
      <ColumnDirective field='source' headerText='Source of Funding' width='80' editType='stringedit'/>
      <ColumnDirective field='organization' headerText='Organization' width='80' editType='stringedit'/>
      <ColumnDirective field='amount' headerText='Amount' width='60' format="C" textAlign="Right"
                    editType="numericedit"
                    edit={{
                      params: {
                        validateDecimalOnType: true,
                        decimals: 0,
                        format: "C",
                        showSpinButton: false,
                      },
                    }}/>
      
      <ColumnDirective field='date_assistance' headerText='Date' width='60' editType='datepickeredit' edit={datepickerparams}/>
      <ColumnDirective field='photo_details' headerText='Photos' width='60' template={template} edit={imageEditDetails}/>
    </ColumnsDirective>
    <Inject services={[Edit, Toolbar]} />
  </GridComponent>
  <ImageDialog
  isOpen={isDialogOpen}
  onClose={() => setIsDialogOpen(false)}
  images={selectedImage}
/>
</>
  );
};

export default AssistanceLog;
