import React, { useState } from "react";
import weatherRadioIcon from "../../icons/Preparedness/weatherRadio.png";
import weatherRadioIcon2 from "../../icons/Preparedness/weatherRadio2.png";
import ready from "../../icons/Preparedness/ready.png";
import pdf1 from "../../icons/Preparedness/Red-Cross-Get-Your-Pet-Ready-22.pdf";
import pdf2 from "../../icons/Preparedness/My-Insurance-Doesnt-Cover-What.pdf";

const Preparedness = () => {
  const WeatherRadio = [
    {
      title: "NOAA Weather Radio Info",
      description:
        "NOAA Weather Radio All Hazards (NWR) is a nationwide network of radio stations broadcasting continuous weather information directly from the nearest National Weather Service office.",
      link: "https://www.weather.gov/nwr/",
    },
    {
      title: "NOAA Weather Radios-All Hazards: Saving Lives Across the Country",
      description:
        "NOAA Weather Radio-All Hazards provides weather reports and information to help you plan your day, and also broadcasts special National Weather Service warnings, watches, forecasts, and other hazard information 24 hours a day.",
      link: "https://celebrating200years.noaa.gov/transformations/weather/radio.html",
    },
  ];

  const GeneralDisasterInfo = [
    {
      title: "Are You Ready?",
      description:
        "This comprehensive guide will provide you with detailed information on how best to prepare for disasters within your family and in your community.",
      link: "https://www.ready.gov/sites/default/files/2021-11/are-you-ready-guide.pdf",
    },
    {
      title: "Pet Disaster Preparedness Kit",
      description: "Do you have an emergency preparedness plan for your pet?",
      link: "https://www.cdc.gov/healthypets/keeping-pets-and-people-healthy/emergencies/pet-disaster-prep-kit.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fhealthypets%2Femergencies%2Fpet-disaster-prep-kit.html",
    },
    {
      title: "Pet Safety in Emergencies",
      description:
        "Learn how to make a plan to keep pets safe in an emergency.",
      link: "https://www.cdc.gov/healthypets/keeping-pets-and-people-healthy/emergencies.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fhealthypets%2Femergencies%2Findex.html",
    },
    {
      title: "Your Pet - Be Ready",
      description:
        "Your pets are an important member of your family, so they need to be included in your family’s emergency plan.",
      link: "https://www.ready.gov/pets",
    },
    {
      title: "Disaster Preparedness and Recovery for Older Adults",
      description:
        "While everyone is at risk during a natural weather-related disaster or similar emergency, older adults can be especially vulnerable during these challenging times.",
      link: "https://www.nia.nih.gov/health/disaster-preparedness-and-recovery-older-adults",
    },
    {
      title: "10 Emergency Kit Essentials - For Seniors",
      description:
        "Older adults can be especially vulnerable during severe weather and natural disasters.",
      link: "https://www.nia.nih.gov/health/infographics/10-emergency-kit-essentials",
    },
    {
      title: "Coping Tips for Traumatic Events and Disasters - Mental Health",
      description: "Emotional distress can happen before and after a disaster.",
      link: "https://www.samhsa.gov/find-help/disaster-distress-helpline/coping-tips",
    },

    {
      title: "Hawaii Disaster Kit Article",
      description:
        "Guidance on how to prepare an emergency disaster kit for families in Hawaii, ensuring readiness in case of any natural disasters.",
      link: "https://health.hawaii.gov/prepare/protect-your-family/prepare-an-emergency-kit/",
    },
    {
      title: "MY INSURANCE DOESN’T COVER WHAT?",
      description:
        "Avoid surprises by understanding your homeowners insurance policy",
      link: pdf2,
    },
    {
      title: "Hawaiian Electric - Handbook for Emergency Preparedness",
      description:
        "A comprehensive guide from Hawaiian Electric on emergency preparedness, covering topics like power outages, natural disaster readiness, and safety.",
      link: "https://view.hawaiianelectric.com/handbook-for-emergency-preparedness/page/1",
    },
  ];

  const GeneralDisasterInfoVideos = [
    {
      VideoUrl: "https://www.youtube.com/watch?v=hhQqujnxmxk",
      title: "Emergency Preparedness | How Ready Are You, Really?",
      author: "Pacific Gas & Electric",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=0zCl5MuiOu4",
      title: "Important Things To Know Before a Disaster",
      author: "FEMA",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=juB48LbB58s",
      title: "Make Sure Your Pets Are Ready For Disasters",
      author: "FEMA",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=aUbSF_S20bE",
      title: "Preparing Makes Sense for Pet Owners",
      author: "FEMA",
    },

    {
      VideoUrl: "https://www.youtube.com/watch?v=j_KiM3Clnp8",
      title: "Video from Hawaii EM on Proper Water Storage",
      author: "",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=pPqDwxgwixE",
      title:
        "What to do in case of a Tsunami in Hawaii - Marshallese Subtitles",
      author: "",
    },
  ];

  const TornadoArticle = [
    {
      title: "Tornado Preparedness Checklist",
      description:
        "A tornado is a tube of spinning air that forms from a thunderstorm and touches the ground.",
      link: "https://www.redcross.org/content/dam/redcross/get-help/pdfs/tornado/EN_Tornado-Safety-Checklist.pdf",
    },
    {
      title: "Understand Tornado Alerts",
      description:
        "The National Weather Service has three key alerts to watch out for.",
      link: "https://www.weather.gov/safety/tornado-ww",
    },
    {
      title: "Preparing for a Tornado",
      description:
        "Keep yourself and your loved ones safe by preparing in advance for tornadoes.",
      link: "https://www.cdc.gov/disasters/tornadoes/prepared.html",
    },
    {
      title: "Stay Safe During a Tornado",
      description:
        "During a tornado, people face hazards from extremely high winds and risk being struck by flying and falling objects.",
      link: "https://www.cdc.gov/disasters/tornadoes/during.html",
    },
    {
      title: "What to do During a Tornado",
      description: "Find out what you can do when a tornado strikes.",
      link: "https://www.weather.gov/safety/tornado-during",
    },
    {
      title: "Tornado | Signs and Clues",
      description:
        "Tornadoes can destroy buildings, flip cars, and create deadly flying debris.",
      link: "https://community.fema.gov/ProtectiveActions/s/article/Tornado-Signs-and-Clues",
    },
    {
      title: "Prepare! Don't let Tornadoes Take You by Surprise",
      description: "Preparation is key to staying safe and minimizing impacts.",
      link: "https://www.weather.gov/safety/tornado-prepare",
    },
    {
      title: "What Should You Do Before a Tornado?",
      description:
        "A tornado is a tube of spinning air that forms from a thunderstorm and touches the ground.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/tornado.html",
    },
    {
      title:
        "Find Shelter Locations from High Winds in the Places Where You Spend a Lot of Time",
      description: "Find a safe room built to withstand high winds.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/tornado.html",
    },
    {
      title: "What Should You Do During a Tornado?",
      description:
        "A tornado is a tube of spinning air that forms from a thunderstorm and touches the ground.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/tornado.html",
    },
  ];

  const TornadoVideos = [
    {
      VideoUrl: "https://www.youtube.com/watch?v=KvLNySr4Iw4",
      title: "Get Weather Ready - Before a Tornado",
      author: "usoceangov",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=_5TiTfuvotc",
      title: "Get Weather Ready: During a Tornado",
      author: "National Weather Service (NWS)",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=UQ94ESZulA8",
      title: "Get Weather Ready: After a Tornado",
      author: "National Weather Service (NWS)",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=lUIsx0LdO5k",
      title: "Surviving the Tornado",
      author: "The Weather Channel",
    },
  ];

  const FloodArticle = [
    {
      title: "FEMA Flood Map Service Center: Search By Address",
      description:
        "Enter an address, place, or coordinates to find your flood map.",
      link: "https://msc.fema.gov/portal/search",
    },
    {
      title: "Flood Warning VS. Watch",
      description:
        "A Flood Watch is issued when conditions are favorable for flooding.",
      link: "https://www.weather.gov/safety/flood-watch-warning",
    },
    {
      title: "Preparing for a Flood",
      description:
        "Make a plan for your household, including your pets, so that you and your family know what to do, where to go, and what you will need to protect yourselves from flooding.",
      link: "https://www.ready.gov/floods#prepare",
    },
    {
      title: "Preparing for a Flood",
      description: "Have a plan ready in case there is a flood in your area.",
      link: "https://www.cdc.gov/disasters/floods/readiness.html",
    },
    {
      title: "Flood Safety Tips and Resources",
      description:
        "Flooding is a coast-to-coast threat to some part of the United States and its territories nearly every day of the year.",
      link: "https://www.weather.gov/safety/flood",
    },
    {
      title: "Before a Flood",
      description:
        "Sometimes floods develop slowly and forecasters can anticipate where a flood will happen days or weeks before it occurs.",
      link: "https://www.weather.gov/safety/flood-before",
    },
    {
      title: "During a Flood",
      description:
        "During a flood, water levels and the rate the water is flowing can quickly change.",
      link: "https://www.weather.gov/safety/flood-during",
    },
    {
      title: "Staying Safe During a Flood",
      description: "Evacuate immediately, if told to evacuate.",
      link: "https://www.ready.gov/floods#during",
    },
  ];

  const FloodVideos = [
    {
      VideoUrl: "https://www.youtube.com/watch?v=5JUsYrjg6xU",
      title: "Turn Around Don't Drown",
      author: "NOAA National Weather Service",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=43M5mZuzHF8",
      title: "How to Prepare for a Flood | Disasters",
      author: "American Red Cross",
    },
  ];

  const FireArticle = [
    {
      title: "Learn About Fires",
      description:
        "Fire is FAST! In less than 30 seconds a small flame can turn into a major fire.",
      link: "https://www.ready.gov/home-fires#about",
    },
    {
      title: "Before a Fire",
      description:
        "If you are insured, contact your insurance company for detailed instructions on protecting your property, conducting inventory and contacting fire damage restoration companies.",
      link: "https://www.ready.gov/home-fires#before",
    },
    {
      title: "During a Fire",
      description:
        "Drop down to the floor and crawl low, under any smoke to your exit.",
      link: "https://www.ready.gov/home-fires#during",
    },
    {
      title: "Prevent Home Fires",
      description: "Home fires can be preventable.",
      link: "https://www.ready.gov/home-fires#prevent",
    },
    {
      title: "Home Fire - Preparedness Checklist",
      description:
        "Home fires are the most common type of emergency in the United States, and can cause deaths, injuries and property loss within minutes of breaking out.",
      link: "https://www.redcross.org/content/dam/redcross/get-help/pdfs/home-fire/EN_Home-Fire-Safety-Checklist.pdf",
    },
    {
      title: "7 Ways To Prepare For A Home Fire",
      description: "Install the right number of smoke alarms.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/home-fire-preparedness.html",
    },
    {
      title: "What To Do If a Home Fire Starts",
      description:
        "If a Fire Starts: Know how to safely operate a fire extinguisher.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/if-a-fire-starts.html",
    },
    {
      title: "Preparing for Wildfires",
      description:
        "More and more people make their homes in areas that are prone to wildfires.",
      link: "https://www.cdc.gov/disasters/wildfires/beforefire.html",
    },
    {
      title: "Stay Safe During a Wildfire",
      description: "Reduce your exposure to wildfire smoke.",
      link: "https://www.cdc.gov/disasters/wildfires/duringfire.html",
    },
    {
      title: "What Should You Do Before a Wildfire Starts?",
      description:
        "Learn how to protect yourself and your family from a wildfire, evacuate safely during a wildfire, and how to stay healthy when you return home.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/wildfire.html",
    },
    {
      title: "What Should You Do During a Wildfire?",
      description:
        "Learn how to protect yourself and your family from a wildfire, evacuate safely during a wildfire, and how to stay healthy when you return home.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/wildfire.html",
    },

    {
      title: "Wildfire in Maui: What Can We Learn?",
      description:
        "An analysis of the causes and consequences of the Maui wildfires, with a focus on the lessons that can be drawn to improve future wildfire preparedness and response.",
      link: "https://www1.wsrb.com/blog/wildfire-in-maui-what-can-we-learn",
    },
    {
      title: "Preliminary After-Action Report: 2023 Maui Wildfire",
      description:
        "A preliminary report from FEMA summarizing the response and key takeaways from the 2023 Maui wildfires.",
      link: "https://www.usfa.fema.gov/blog/preliminary-after-action-report-2023-maui-wildfire/",
    },

    {
      title: "Learn About Fires",
      description:
        "An overview of how fires start, spread, and how you can protect yourself and your home from fire-related disasters.",
      link: "https://www.ready.gov/home-fires#about",
    },
    {
      title: "Before a Fire",
      description:
        "Preventive measures and preparation steps to take before a fire occurs in your home or area.",
      link: "https://www.ready.gov/home-fires#before",
    },
    {
      title: "During a Fire",
      description:
        "A guide on what to do during a fire emergency, including evacuation and safety procedures.",
      link: "https://www.ready.gov/home-fires#during",
    },
    {
      title: "Prevent Home Fires",
      description:
        "Tips and strategies for preventing fires in the home, from maintaining smoke alarms to safe cooking practices.",
      link: "https://www.ready.gov/home-fires#prevent",
    },
    {
      title: "Home Fire - Preparedness Checklist",
      description:
        "A checklist from the Red Cross to help you prepare your home and family in case of a fire emergency.",
      link: "https://www.redcross.org/content/dam/redcross/get-help/pdfs/home-fire/EN_Home-Fire-Safety-Checklist.pdf",
    },
    {
      title: "7 Ways To Prepare For A Home Fire",
      description:
        "Seven essential tips to prepare your home and loved ones for a potential fire emergency.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/home-fire-preparedness.html",
    },
    {
      title: "What To Do If a Home Fire Starts",
      description:
        "A step-by-step guide on what actions to take if a fire breaks out in your home.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/if-a-fire-starts.html",
    },
    {
      title: "Preparing for Wildfires",
      description:
        "Important steps to take before a wildfire threatens your area, including creating defensible space and evacuation plans.",
      link: "https://www.cdc.gov/disasters/wildfires/beforefire.html",
    },
    {
      title: "Stay Safe During a Wildfire",
      description:
        "A guide on how to stay safe during an active wildfire, including evacuation tips and how to protect yourself from smoke.",
      link: "https://www.cdc.gov/disasters/wildfires/duringfire.html",
    },
    {
      title: "What Should You Do Before a Wildfire Starts?",
      description:
        "Preparation tips for homeowners to stay ready before a wildfire threatens, such as creating an evacuation plan and emergency kit.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/wildfire.html",
    },
    {
      title: "What Should You Do During a Wildfire?",
      description:
        "Guidelines for staying safe and protecting your property during an active wildfire event.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/wildfire.html",
    },
  ];

  const FireVideos = [
    {
      VideoUrl: "https://www.youtube.com/watch?v=T5-JYXkry88",
      title: "Home Fire Safety - Are you prepared? 10 must do tips",
      author: "Prevention 1st",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=Vc-AkbpdSYk",
      title: "Every Second Counts in a Home Fire—Practice Your Escape Plan",
      author: "National Fire Protection Association (NFPA)",
    },

    {
      VideoUrl: "https://www.youtube.com/watch?v=qAOuUujKWVA",
      title:
        "Maui Wildfire Exposure Study: Community Health, Wellbeing, and Resilience",
      author: "",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=UGA_zxP4d78",
      title:
        "Purdue Expert: Lessons Learned from Maui Wildfires RYAN WATCH THIS - Chris D.",
      author: "",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=5Ns-52ijX4k",
      title: "Hawaii Fire - Wildland Fire Prevention",
      author: "",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=6mv95mKZOmc",
      title: "Ready, Set, Go Action Guide",
      author: "",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=dpsvUqNyroQ",
      title: "See Something? Say Something!",
      author: "",
    },
  ];

  const HurricaneArticle = [
    {
      title: "Hurricane Safety Tips and Resources",
      description:
        "By knowing what actions to take before the hurricane season begins, when a hurricane approaches, and when the storm is in your area, as well as what to do after a hurricane leaves your area, you can increase your chance of survival.",
      link: "https://www.weather.gov/safety/hurricane",
    },
    {
      title:
        "Hurricane and Tropical Storm Watches, Warnings, Advisories and Outlooks",
      description:
        "When coastal tropical storm or hurricane watches or warnings are in effect, the NHC and CPHC issue Tropical Cyclone Public advisories every 3 hours.",
      link: "https://www.weather.gov/safety/hurricane-ww",
    },
    {
      title: "Prepare for Hurricanes",
      description:
        "Learn how to prepare for a hurricane, stay safe during a hurricane, and what to do when returning home from a hurricane.",
      link: "https://www.ready.gov/hurricanes#before",
    },
    {
      title: "Preparing for a Hurricane or Other Tropical Storm",
      description:
        "Get ready for hurricane season by making a plan, gathering supplies, and getting your home, car, and family ready for a storm.",
      link: "https://www.cdc.gov/disasters/hurricanes/before.html",
    },
    {
      title: "What to Do Before the Tropical Storm or Hurricane",
      description:
        "The best time to prepare for a hurricane is before hurricane season begins on June 1.",
      link: "https://www.weather.gov/safety/hurricane-plan",
    },
    {
      title: "Be Ready! Hurricanes",
      description:
        "Know how to turn off your gas, electricity, and water in case you need to evacuate.",
      link: "https://www.cdc.gov/orr/infographics/00_docs/beready_hurricanes.pdf",
    },
    {
      title: "Stay Safe During a Hurricane",
      description: "Evacuate immediately, if told to evacuate.",
      link: "https://www.ready.gov/hurricanes#during",
    },
    {
      title: "Actions to Take When a Tropical Storm or Hurricane Threatens",
      description:
        "When a hurricane threatens your community, be prepared to evacuate if you live in a storm surge risk area.",
      link: "https://www.weather.gov/safety/hurricane-action",
    },
  ];

  const HurricaneVideos = [
    // {
    //   VideoUrl: "",
    //   title: "",
    //   author: "",
    // },
  ];

  const EarthquakeArticle = [
    {
      title: "Earthquake Preparedness Checklist",
      description:
        "An earthquake is a sudden, rapid shaking of the earth caused by the shifting of underground rock.",
      link: "https://www.redcross.org/content/dam/redcross/get-help/pdfs/earthquake/EN_Earthquake-Preparedness-Checklist.pdf",
    },
    {
      title: "Prepare Before an Earthquake",
      description:
        "The best time to prepare for any disaster is before it happens.",
      link: "https://www.ready.gov/earthquakes#before",
    },
    {
      title: "Preparing for an Earthquake",
      description:
        "The key to surviving an earthquake and reducing your risk of injury lies in planning, preparing, and practicing what you and your loved ones will do if it happens.",
      link: "https://www.cdc.gov/disasters/earthquakes/prepared.html",
    },
    {
      title: "What Should You Do Before an Earthquake?",
      description:
        "Learn what to do during an earthquake, and how to strengthen your home to prepare for an earthquake.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html",
    },
    {
      title: "Stay Safe During an Earthquake",
      description:
        "In most cases, drop, cover, and hold on to protect yourself during an earthquake.",
      link: "https://www.cdc.gov/disasters/earthquakes/during.html",
    },
    {
      title: "What Should You Do During an Earthquake?",
      description:
        "Learn what to do during an earthquake, and how to strengthen your home to prepare for an earthquake.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html",
    },
    {
      title: "Stay Safe During an Earthquake",
      description: "If an earthquake happens, protect yourself right away.",
      link: "https://www.ready.gov/earthquakes#during",
    },
  ];

  const EarthquakeVideos = [
    {
      VideoUrl: "https://www.youtube.com/watch?v=BLEPakj1YTY",
      title: "How to Protect Yourself During an Earthquake | Disasters",
      author: "American Red Cross",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=MKILThtPxQs",
      title: "When The Earth Shakes - Animated Video",
      author: "Federal Emergency Management Agency (FEMA)",
    },
    // {
    //   VideoUrl: "https://www.youtube.com/playlist?list=PLs1gMujRSBY2t7JB4VS-AymFwN-6Lvg20",
    //   title: "Earthquake Drills",
    //   author: "California Department of Education",
    // },
    {
      VideoUrl: "https://www.youtube.com/watch?v=Z4suAKDcaCU",
      title: "How to Stay Safe",
      author: "American Red Cross",
    },
    {
      VideoUrl: "https://www.youtube.com/watch?v=A_j6_7bmY20",
      title: "How to stay safe in an earthquake",
      author: "FEMA",
    },
  ];

  const FirePetsArticle = [
    {
      title: "Pet Fire Safety",
      description:
        "The best way to protect your pets from the effects of a fire is to include them in your family plan.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/pet-fire-safety.html",
    },
    {
      title: "Hawaii Red Cross - Get Your Pet Ready",
      description:
        "A guide from the Hawaii Red Cross on how to prepare your pets for disasters, ensuring their safety and wellbeing.",
      link: pdf1,
    },
    {
      title: "Fire Safety",
      description:
        "A home fire is a devastating event, and one that you never count on happening. Your children are most at risk when this disaster occurs.",
      link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/fire/fire-safety-for-kids.html",
    },
  ];

  const FirePetsVideos = [];

  // Add these state variables at the beginning of your component
  const [GeneralDisasterInfoModals, setGeneralDisasterInfoModals] = useState(
    GeneralDisasterInfoVideos.map(() => false)
  );

  const openPreparednessModal = (index) => {
    const newModals = [...GeneralDisasterInfoModals];
    newModals[index] = true;
    setGeneralDisasterInfoModals(newModals);
  };

  const closePreparednessModal = (index) => {
    const newModals = [...GeneralDisasterInfoModals];
    newModals[index] = false;
    setGeneralDisasterInfoModals(newModals);
  };

  // Add these state variables at the beginning of your component
  const [tornadoModals, setTornadoModals] = useState(
    TornadoVideos.map(() => false)
  );
  const openTornadoModal = (index) => {
    const newModals = [...tornadoModals];
    newModals[index] = true;
    setTornadoModals(newModals);
  };

  const closeTornadoModal = (index) => {
    const newModals = [...tornadoModals];
    newModals[index] = false;
    setTornadoModals(newModals);
  };

  // Open and close handlers for flood
  const [floodModals, setFloodModals] = useState(FloodVideos.map(() => false));

  const openFloodModal = (index) => {
    const newModals = [...floodModals];
    newModals[index] = true;
    setFloodModals(newModals);
  };

  const closeFloodModal = (index) => {
    const newModals = [...floodModals];
    newModals[index] = false;
    setFloodModals(newModals);
  };

  // Open and close handlers for fire
  const [fireModals, setFireModals] = useState(FireVideos.map(() => false));

  const openFireModal = (index) => {
    const newModals = [...fireModals];
    newModals[index] = true;
    setFireModals(newModals);
  };

  const closeFireModal = (index) => {
    const newModals = [...fireModals];
    newModals[index] = false;
    setFireModals(newModals);
  };

  // Open and close handlers for hurricane
  const [hurricaneModals, setHurricaneModals] = useState(
    HurricaneVideos.map(() => false)
  );

  const openHurricaneModal = (index) => {
    const newModals = [...hurricaneModals];
    newModals[index] = true;
    setHurricaneModals(newModals);
  };

  const closeHurricaneModal = (index) => {
    const newModals = [...hurricaneModals];
    newModals[index] = false;
    setHurricaneModals(newModals);
  };

  // Open and close handlers for earthquake
  const [earthquakeModals, setEarthquakeModals] = useState(
    EarthquakeVideos.map(() => false)
  );

  const openEarthquakeModal = (index) => {
    const newModals = [...earthquakeModals];
    newModals[index] = true;
    setEarthquakeModals(newModals);
  };

  const closeEarthquakeModal = (index) => {
    const newModals = [...earthquakeModals];
    newModals[index] = false;
    setEarthquakeModals(newModals);
  };

  // Open and close handlers for firepet
  const [firepetModals, setFirepetModals] = useState(
    FirePetsVideos.map(() => false)
  );

  const openFirepetModal = (index) => {
    const newModals = [...firepetModals];
    newModals[index] = true;
    setFirepetModals(newModals);
  };

  const closeFirepetModal = (index) => {
    const newModals = [...firepetModals];
    newModals[index] = false;
    setFirepetModals(newModals);
  };

  const Modal = ({ isOpen, onClose, videoSrc }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div
          className="overflow-hidden rounded-lg bg-black shadow-lg"
          style={{ width: "560px" }}
        >
          <div className="flex items-center justify-between bg-gray-800 p-2 text-white">
            <span className="font-bold">Video</span>
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-black text-gray-300 transition-colors duration-200 ease-in-out hover:border-white hover:text-white focus:outline-none"
              onClick={onClose}
            >
              <span className="text-3xl leading-none">X</span>
            </button>
          </div>
          <div className="p-4">
            <iframe
              width="100%"
              height="315"
              src={videoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex w-full flex-col items-start justify-start gap-2.5 pb-10 text-left text-sm leading-[normal] tracking-[normal] text-darkslategray-100">
      {/* <header className="box-border flex w-[1124.3px] max-w-full flex-col items-start justify-start gap-2.5 py-0 pl-0 pr-5 text-left text-sm text-darkslategray-100">
        <div className="box-border flex w-[998px] max-w-full flex-row items-start justify-start px-0 pb-2.5 pt-0 text-darkslateblue">
          <b className="relative inline-block max-w-full flex-1 uppercase">
            Weather Radios
          </b>
        </div>
        <div className="box-border flex w-[998px] max-w-full flex-row items-start justify-start px-0 pb-[23px] pt-0">
          <b className="relative inline-block max-w-full flex-1">Articles</b>
        </div>
        <div className="flex max-w-full flex-row flex-wrap items-start justify-start gap-4 self-stretch">
          <div className="flex min-w-[300px] max-w-full flex-1 flex-col items-start justify-start gap-2.5">
            <div className="flex w-full max-w-full flex-row items-center justify-start gap-2.5">
              <img
                className="relative h-[75px] w-[75px] flex-shrink-0 object-cover"
                loading="lazy"
                alt="Weather Radio Icon"
                src={weatherRadioIcon}
              />
              <div className="flex min-w-[200px] flex-1 flex-col items-start justify-start gap-[7px]">
                <a
                  className="relative font-semibold text-[inherit] [text-decoration:none]"
                  href="https://www.weather.gov/nwr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NOAA Weather Radio Info
                </a>
                <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                  <a
                    className="relative flex h-auto max-w-full flex-1 items-center text-[inherit] [text-decoration:none]"
                    href="https://www.weather.gov/nwr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NOAA Weather Radio All Hazards (NWR) is a nationwide network
                    of radio stations broadcasting continuous weather
                    information directly from the nearest National Weather
                    Service office.
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex min-w-[300px] max-w-full flex-1 flex-row items-start justify-start gap-2.5">
            <img
              className="relative h-[75px] w-[75px] flex-shrink-0 object-contain"
              loading="lazy"
              alt="Weather Radio Icon 2"
              src={weatherRadioIcon2}
            />
            <div className="box-border flex min-w-[200px] flex-1 flex-col items-start justify-start px-0 pb-0 pt-[7px]">
              <div className="flex max-w-full flex-col items-start justify-start gap-[11px] self-stretch">
                <a
                  className="relative self-stretch font-semibold text-[inherit] [text-decoration:none]"
                  href="https://celebrating200years.noaa.gov/transformations/weather/radio.html"
                  target="_blank"
                >
                  NOAA Weather Radios-All Hazards: Saving Lives Across the
                  Country
                </a>
                <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                  <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                    <a
                      className="relative flex h-auto max-w-full flex-1 items-center text-[inherit] [text-decoration:none]"
                      href="https://celebrating200years.noaa.gov/transformations/weather/radio.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      What's the weather? Thanks to NOAA Weather Radio-All
                      Hazards, you'll always have the answer to that question
                      and access to potentially life-saving emergency
                      information whenever you need it.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header> */}

      {/* Weather Radios ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Weather Radios
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {WeatherRadio.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Weather Radios ARTICLE */}

      {/* <b className="relative self-stretch uppercase text-darkslateblue">
        General Disaster Info
      </b>
      <b className="relative inline-block min-w-[52px] text-darkslategray-200">
        Articles
      </b>
      <div className="box-border flex w-[570px] max-w-full flex-col items-start justify-start gap-2.5 py-0 pl-0 pr-5 text-darkslategray-200">
        {GeneralDisasterInfo.map((item, index) => (
          <div className="flex max-w-full flex-row flex-wrap items-start justify-start gap-[21.6px] self-stretch">
            <div className="relative h-[75px] w-[75px]">
              <div className="absolute left-[75px] top-[75px] h-full w-full rounded-mini bg-silver-200 [transform-origin:0_0] [transform:_rotate(180deg)]" />
              <img
                className="absolute left-[0px] top-[0px] z-[1] h-full w-full rounded-mini object-cover"
                loading="lazy"
                alt=""
                src={ready}
              />
            </div>
            <div className="box-border flex min-w-[294px] max-w-full flex-1 flex-col items-start justify-start px-0 pb-0 pt-1">
              <div className="flex max-w-full flex-col items-start justify-start gap-3 self-stretch">
                <a
                  className="relative flex w-[312.5px] items-center font-semibold text-[inherit] [text-decoration:none]"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
                <div className="box-border flex max-w-full flex-row items-start justify-start self-stretch py-0 pl-px pr-0 text-gray1-100">
                  <div className="relative inline-block max-w-full flex-1">
                    <a
                      className="relative flex h-auto max-w-full flex-1 items-center text-[inherit] [text-decoration:none]"
                      href={item.link}
                      target="_blank"
                    >
                      {item.description}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <b className="relative inline-block min-w-[46px]">Videos</b>
      </div>
      <section className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch pb-6 text-left text-smi text-darkslategray-200">
        {GeneralDisasterInfoVideos.map((video, index) => (
          <div
            key={index}
            className="flex h-[225px] w-[272px] flex-col items-start justify-start"
          >
            <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
              <div
                className="relative h-full w-full cursor-pointer"
                onClick={() => openPreparednessModal(index)}
              >
                <img
                  className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                  alt={`${video.title} thumbnail`}
                  src={`https://img.youtube.com/vi/${
                    video.VideoUrl.split("v=")[1]
                  }/0.jpg`}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="h-16 w-16 text-white opacity-80"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                  </svg>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                <b className="relative">{video.title}</b>
                <div className="text-3xs relative font-light">
                  {video.author}
                </div>
              </div>
            </div>
            <Modal
              isOpen={GeneralDisasterInfoModals[index]}
              onClose={() => closePreparednessModal(index)}
              videoSrc={`https://www.youtube.com/embed/${
                video.VideoUrl.split("v=")[1]
              }?autoplay=1`}
            />
          </div>
        ))}
      </section> */}

      {/* General Info ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          General Disaster Info
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {GeneralDisasterInfo.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200 ">
          {GeneralDisasterInfoVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openPreparednessModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={GeneralDisasterInfoModals[index]}
                onClose={() => closePreparednessModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* General Info ARTICLE */}

      {/* TORNADO ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Tornado
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {TornadoArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {TornadoVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={tornadoModals[index]}
                onClose={() => closeTornadoModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* TORNADO ARTICLE */}

      {/* FLOOD ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Flood
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {FloodArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {FloodVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={floodModals[index]}
                onClose={() => closeFloodModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* FLOOD ARTICLE */}

      {/* FIRE ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Fire
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {FireArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {FireVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={fireModals[index]}
                onClose={() => closeFireModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* FIRE ARTICLE */}

      {/* HURRICANE ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Hurricane
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {HurricaneArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {HurricaneVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={hurricaneModals[index]}
                onClose={() => closeHurricaneModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* HURRICANE ARTICLE */}

      {/* EARTHQUAKE ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Earthquake
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {EarthquakeArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {EarthquakeVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={earthquakeModals[index]}
                onClose={() => closeEarthquakeModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* EARTHQUAKE ARTICLE */}

      {/* FIRE PETS ARTICLE */}
      <section className="flex w-full flex-col gap-3">
        <b className="relative self-stretch uppercase text-darkslateblue">
          Fire Pets
        </b>
        <b className="relative self-stretch">Articles</b>
        <div className="lg:pl-9 lg:pr-9 lg:box-border min- box-border flex w-[1076px] max-w-full flex-row flex-wrap items-center justify-center gap-x-[21px] gap-y-[17.6px] rounded-[20px] px-[73px] py-1.5 text-left text-sm text-darkslateblue">
          {FirePetsArticle.map((item, index) => (
            <div
              key={index}
              className="flex w-[452px] max-w-full flex-col items-start justify-start gap-3"
            >
              <div className="relative flex w-[355.2px] max-w-full items-center font-semibold">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.title}
                </a>
              </div>
              <div className="relative self-stretch text-gray1-100">
                <a
                  className="text-[inherit] no-underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.description}
                </a>
              </div>
            </div>
          ))}
        </div>
        <b className="relative self-stretch">Videos</b>
        <div className="flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] self-stretch text-left text-smi text-darkslategray-200">
          {FirePetsVideos.map((video, index) => (
            <div
              key={index}
              className="flex w-[272px] flex-col items-start justify-start"
            >
              <div className="flex flex-1 flex-col items-start justify-start gap-[5px] self-stretch">
                <div
                  className="relative h-full w-full cursor-pointer"
                  onClick={() => openTornadoModal(index)}
                >
                  <img
                    className="relative max-h-full max-w-full flex-1 self-stretch overflow-hidden object-cover"
                    alt={`${video.title} thumbnail`}
                    src={`https://img.youtube.com/vi/${
                      video.VideoUrl.split("v=")[1]
                    }/0.jpg`}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg
                      className="h-16 w-16 text-white opacity-80"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-px py-0 pl-0 pr-5">
                  <b className="relative">{video.title}</b>
                  <div className="text-3xs relative font-light">
                    {video.author}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={firepetModals[index]}
                onClose={() => closeFirepetModal(index)}
                videoSrc={`https://www.youtube.com/embed/${
                  video.VideoUrl.split("v=")[1]
                }?autoplay=1`}
              />
            </div>
          ))}
        </div>
      </section>
      {/* FIRE PETS ARTICLE */}
    </div>
  );
};

export default Preparedness;
