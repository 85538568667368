import React from "react";

const Journals = ({ className = "" }) => {
  const now = new Date();
  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
  });
  return (
    <div
      className={`text-5xl mt-2 box-border flex w-[1628px] max-w-full flex-col items-start justify-start gap-2.5 rounded-3xs border-[1px] border-solid border-silver-100 bg-white px-[19px] py-[18px] text-left leading-[normal] tracking-[normal] text-heading-title-color ${className}`}
    >
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p>
          The Disaster Journal provides a space for you to share your personal
          experiences and reflections throughout the recovery process. Your
          story is a vital part of understanding the full impact of the disaster
          and will help shape future recovery efforts. Please take the time to
          document your journey and insights.
        </p>
      </div>
      <div className="lg:flex-wrap flex max-w-full flex-row items-center justify-between gap-5 self-stretch text-xs text-dodgerblue">
        <div className="flex w-[159px] flex-col items-start justify-start">
          <a className="relative inline-block min-w-[44px] font-bold leading-[21px] text-[inherit] [text-decoration:none]">
            Journal
          </a>
          <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
            Last Edited: 4 minutes ago
          </div>
        </div>
        <div className="flex w-[762.5px] max-w-full flex-row items-center justify-end">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15.5L12 8.5L19 15.5"
              stroke="#152536"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex max-w-full flex-row flex-wrap items-start justify-start gap-5 self-stretch">
        <div className="flex w-[35px] flex-col items-start justify-start">
          <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]">
            17
          </a>
          <b className="relative flex w-[34px] items-center text-xs">MAY</b>
        </div>
        <div className="lg:min-w-full mq825:pr-[49px] mq825:box-border mq1450:pr-[99px] mq1450:box-border box-border flex min-w-[996px] max-w-full flex-1 flex-col items-start justify-start gap-[15px] py-0 pl-0 pr-[198px] text-xs mq450:box-border mq450:pr-5">
          <div className="mq1450:flex-wrap box-border flex max-w-full flex-row items-start justify-start gap-5 self-stretch px-0 py-0">
            <a className="relative inline-block min-w-[43px] whitespace-nowrap text-[inherit] [text-decoration:none]">
              8:33 am
            </a>
            <div className="relative flex w-[1450px] max-w-[109%] shrink-0 items-center">{`My House was burned down by the Fire `}</div>
          </div>
          <div className="mq1450:flex-wrap box-border flex max-w-full flex-row items-start justify-start gap-5 self-stretch px-0 py-0">
            <div className="relative inline-block min-w-[43px] whitespace-nowrap">
              9:42 am
            </div>
            <div className="relative flex w-[1450px] max-w-[109%] shrink-0 items-center">{`The fire was extinguished and we were given first aid `}</div>
          </div>
          <div className="mq1450:flex-wrap box-border flex max-w-full flex-row items-start justify-start gap-5 self-stretch px-0 py-0">
            <div className="relative inline-block min-w-[44px] whitespace-nowrap">
              5:42 pm
            </div>
            <div className="relative flex w-[1450px] max-w-[109%] shrink-0 items-center">
              Donation came through
            </div>
          </div>
        </div>
      </div>
      <div className="box-border flex max-w-full flex-row flex-wrap items-center justify-start gap-5 self-stretch py-0 pl-0 pr-[19px]">
        <div className="flex w-[34px] flex-col items-start justify-start">
          <b className="mq450:text-lgi relative inline-block min-w-[27px]">
            {formattedDate.split(" ")[1].toUpperCase()}
          </b>
          <b className="relative self-stretch text-sm">
            {formattedDate.split(" ")[0].toUpperCase()}
          </b>
        </div>
        <div className="lg:min-w-full box-border flex min-w-[985px] max-w-full flex-1 flex-row flex-wrap items-center justify-start gap-x-5 gap-y-[18px] py-0 pl-0 pr-0.5 text-xs text-gray-500">
          <div className="text-gray1 relative inline-block min-w-[45px] whitespace-nowrap leading-[20px]">
            {formattedTime}
          </div>
          <div className="mq825:min-w-full box-border flex max-h-[44px] min-h-[38px] min-w-[580px] max-w-full flex-1 flex-row items-center justify-start rounded-md border-[1px] border-solid border-gray-300 bg-gray-200 px-4 py-2.5">
            <div className="relative">
              Recording your experiences can be a powerful way to document what
              you’ve been through. Write it here.
            </div>
          </div>
          <div className="relative hidden w-[50px] font-inter leading-[12px]">
            HelpText
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journals;
